/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
import useSWR from 'swr';
import { fetcher } from '../api/fetcher';

import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Switch,
  useTheme,
  Toolbar,
  LinearProgress,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const DealerSettings = ({ token, dealer }) => {
  const theme = useTheme();
  const [open, setOpen] = useState();

  const setPlaceLocation = () => {};

  const { data: locations, isValidating: loadingLocations } = useSWR(
    token ? [process.env.REACT_APP_BACKEND_URL + `/admin/locations`, token, dealer.id] : null,
    fetcher
  );
  const { data: places, isValidating: loadingPlaces } = useSWR(
    token ? [process.env.REACT_APP_BACKEND_URL + `/admin/places`, token, dealer.id] : null,
    fetcher
  );

  const Location = ({ location }) => {
    return (
      <TableRow size='small' selected={location.id ? false : true}>
        <TableCell component='th' scope='row' colSpan={location.name ? 1 : 2}>
          {location.name}
        </TableCell>
        {location.name && <TableCell>{location.postLocation}</TableCell>}
        <TableCell align='right'>
          <Switch checked={true} disabled inputProps={{ 'aria-label': 'secondary checkbox' }} />
        </TableCell>
      </TableRow>
    );
  };

  const Place = ({ place, setPlaceLocation }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow size='small'>
          <TableCell style={{ width: '30px' }}>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row' colSpan={2}>
            {place.name}
          </TableCell>
          <TableCell align='right'>
            {place.deleted && (
              <IconButton color='secondary' aria-label='delete' style={{ padding: '3px' }}>
                <UndoIcon fontSize='small' />
              </IconButton>
            )}
            {!place.deleted && (
              <IconButton aria-label='delete' style={{ padding: '3px' }}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open}>
              <Table size='small' style={{ backgroundColor: theme.palette.primary.light }}>
                <TableBody>
                  {place.locations.map((location) => (
                    <Location
                      key={location.name}
                      location={location}
                      hideId={true}
                      setLocation={setPlaceLocation}
                      placeName={place.name}
                    />
                  ))}
                  <TableRow size='small'>
                    <TableCell
                      component='th'
                      scope='row'
                      colSpan={3}
                      align='center'
                      style={{ backgroundColor: 'unset' }}
                    >
                      <IconButton aria-label='Add' style={{ backgroundColor: theme.palette.secondary.dark }}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const Details = ({ locations }) => {
    return (
      <Fragment>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ marginBottom: '50px' }}>
            <Toolbar size='small' variant='dense'>
              <Typography>Butikker</Typography>
            </Toolbar>
            {locations ? (
              <Table size='small'>
                <TableBody>
                  {locations
                    .filter((location) => !location.deleted || location.id)
                    .map((location) => (
                      <Location key={location.id} location={location} />
                    ))}
                </TableBody>
              </Table>
            ) : (
              <LinearProgress />
            )}
          </TableContainer>
        </Grid>
        {
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ marginBottom: '50px' }}>
              <Toolbar size='small' variant='dense'>
                <Typography>Områder</Typography>
              </Toolbar>
              {places ? (
                <Table size='small'>
                  <TableBody>
                    {places.map((place) => (
                      <Place key={place.name} place={place} setPlaceLocation={setPlaceLocation} />
                    ))}

                    <TableRow size='small'>
                      <TableCell component='th' scope='row' colSpan={4} align='center'>
                        <IconButton
                          aria-label='Add'
                          size='medium'
                          style={{ backgroundColor: theme.palette.secondary.light }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <LinearProgress />
              )}
            </TableContainer>
          </Grid>
        }
      </Fragment>
    );
  };

  return dealer ? (
    <>
      <Paper sx={{ minHeight: '100px', padding: '20px', marginBottom: '20px' }}>
        <Typography>
          Forhandler: <small>{dealer.name}</small>
        </Typography>
        <Typography>
          Id: <small>{dealer._id}</small>
        </Typography>
      </Paper>
      <Details open={open} setOpen={setOpen} locations={locations} />
    </>
  ) : (
    <LinearProgress />
  );
};
