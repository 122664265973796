import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Admin } from './admin/Admin';
import { LocationsList } from './features/locations/LocationsList';
import { CarCarousel } from './features/carousel/Carousel';

// "@emotion/react": "^11.5.0",
// "@emotion/styled": "^11.3.0",

// "@material-ui/core": "^4.12.2",
// "@material-ui/lab": "^4.0.0-alpha.60",
// import { ThemeProvider, createTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import CookieConsent from 'react-cookie-consent';

import ReactGA from 'react-ga';
import { Car } from './features/cars/Car';
ReactGA.initialize('UA-214760105-1');

function App() {
  const [primary, setPrimary] = useState({ main: '#666', light: '#666' });
  const [secondary, setSecondary] = useState({ main: '#666', light: '#666' });
  const [paddingRight, setPaddingRight] = useState('20px');

  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         primary: primary,
  //         secondary: secondary
  //       }
  //       // components: {
  //       //   MuiBadge: {
  //       //     styleOverrides: {
  //       //       // Name of the slot
  //       //       root: {
  //       //         // Some CSS
  //       //         width: '-webkit-fill-available'
  //       //         // fontSize: '1rem',
  //       //       }
  //       //     }
  //       //   }
  //       // }
  //     }),
  //   [primary, secondary]
  // );

  // const theme = createTheme({
  //   palette: {
  //     primary: primary,
  //     secondary: secondary
  //   },
  //   components: {
  //     MuiPaper: {
  //       styleOverrides: {
  //         root: () => ({
  //           backgroundColor: primary.light
  //         })
  //       }
  //     },
  //     MuiMenuItem: {
  //       styleOverrides: {
  //         root: ({ ownerState }) => ({
  //           ...(ownerState.selected === true && {
  //             color: primary.light,
  //             '&:hover': {
  //               backgroundColor: 'white'
  //             }
  //           })
  //         })
  //       }
  //     },
  //     MuiInput: {
  //       styleOverrides: {
  //         root: () => ({
  //           padding: '5px 10px',
  //           '&:hover:not(.Mui-disabled):before': {
  //             borderBottom: 'none'
  //           }
  //         }),
  //         underline: ({ ownerState }) => {
  //           // console.log(ownerState);
  //           return {
  //             '&:before': {
  //               border: 'none'
  //             },
  //             '&:after': {
  //               border: 'none'
  //             }
  //           };
  //         }
  //       }
  //     },
  //     MuiGrid: {
  //       styleOverrides: {
  //         // item: () => ({
  //         //   paddingTop: '50px',
  //         // })
  //       }
  //     }
  //   }
  // });

  const theme = {
    palette: {
      primary: primary,
      secondary: secondary
    },
    components: {
      // MuiPaper: {
      //   styleOverrides: {
      //     root: () => ({
      //       backgroundColor: primary.light
      //     })
      //   }
      // },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.selected === true && {
              color: primary.light,
              '&:hover': {
                backgroundColor: 'white'
              }
            })
          })
        }
      },
      MuiInput: {
        styleOverrides: {
          root: () => ({
            padding: '5px 10px',
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none'
            }
          }),
          underline: ({ ownerState }) => {
            // console.log(ownerState);
            return {
              '&:before': {
                border: 'none'
              },
              '&:after': {
                border: 'none'
              }
            };
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          // item: () => ({
          //   paddingTop: '50px',
          // })
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '@media (min-width: 1200px)': {
              maxWidth: 'none'
            }
          }
        }
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            width: 'unset'
          }
        }
      }
    }
  };

  const setTheme = ({ palette }) => {
    setPrimary({
      main: palette?.primary?.main ? palette.primary.main : primary.main,
      light: palette?.primary?.light ? palette.primary.light : primary.light
    });
    setSecondary({
      main: palette?.secondary?.main ? palette.secondary.main : secondary.main,
      light: palette?.secondary?.light ? palette.secondary.light : secondary.light
    });
  };

  // const setTheme = useCallback(
  //   ({ palette }) => {
  //     setPrimary({
  //       main: palette?.primary?.main ? palette.primary.main : primary.main,
  //       light: palette?.primary?.light ? palette.primary.light : primary.light
  //     });
  //     setSecondary({
  //       main: palette?.secondary?.main ? palette.secondary.main : secondary.main,
  //       light: palette?.secondary?.light ? palette.secondary.light : secondary.light
  //     });
  //   },
  //   [primary, secondary]
  // );

  useEffect(() => {
    if (window.self === window.top) {
      document.body.style.overflow = 'unset';
    }
    if (isMobile || window.location.pathname.indexOf('/carousel') > -1) {
      setPaddingRight(0);
    }
  }, []);

  return (
    <ThemeProvider theme={createTheme(theme)}>
      {/* <CookieConsent
          location='bottom'
          buttonText='Fortsett'
          cookieName={'bra-auto'}
          style={{ background: primary.main }}
          sameSite='strict'
          buttonStyle={{
            background: secondary.light,
            color: 'white',
            padding: '7px 20px',
            border: '1px solid',
            borderRadius: '15px'
          }}
          expires={150}
        >
          Vi bruker informasjonskapsler for å tilpasse og forbedre nettstedet vårt. Ved å fortsette, godtar du at vi
          bruker informasjonskapsler.
        </CookieConsent> */}
      {/* <div>Header</div> */}
      <Router>
        <div className='App' style={{ paddingRight: paddingRight, maxWidth: '1200px' }}>
          <Switch>
            <Route
              path='/admin/:page?/:firstParam?/:secondParam?'
              render={(props) => <Admin {...props} setTheme={setTheme} />}
            />

            <Route
              exact
              path='/images'
              render={() => (
                <div>
                  <h2>404 - Not Found!</h2>
                </div>
              )}
            />

            <Route
              exact
              path='/'
              render={(props) => {
                props.match.params.dealerId = '61560fe0698734b72169b54a';
                return <LocationsList {...props} setTheme={setTheme} />;
              }}
            />
            <Route exact path='/:dealerId' render={(props) => <LocationsList {...props} setTheme={setTheme} />} />
            <Route
              exact
              path='/carousel/:dealerId'
              render={(props) => <CarCarousel {...props} setTheme={setTheme} />}
            />
            <Route
              exact
              path='/:dealerId/:locationId'
              render={(props) => <LocationsList {...props} setTheme={setTheme} />}
            />
            <Route
              exact
              path='/carousel/:dealerId/:locationId'
              render={(props) => <CarCarousel {...props} setTheme={setTheme} />}
            />
            <Route exact path='/car/:dealerId/:carId' render={(props) => <Car {...props} setTheme={setTheme} />} />
            {/* <Route
              exact
              path='/car/:dealerId/:carId'
              render={(props) => <LocationsList {...props} setTheme={setTheme} />}
            /> */}

            <Route
              exact
              path='/car/:dealerId/:carId/:link'
              render={(props) => <LocationsList {...props} setTheme={setTheme} />}
            />

            {/* <Route exact path='/:dealerId/admin' render={(props) => <Admin {...props} setTheme={setTheme} />} /> */}

            <Redirect to='/' />
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
