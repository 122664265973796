/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment, useCallback, useRef } from 'react';
import useSWR from 'swr';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import {
  Typography,
  Button,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Collapse,
  Switch,
  useTheme,
  Toolbar,
  LinearProgress,
  Autocomplete,
  TextField
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { fetcher } from '../api/fetcher';

export const Dealers = ({ token }) => {
  const theme = useTheme();
  const [locations, setLocations] = useState();
  const [open, setOpen] = useState();

  const { data: dealers, mutate } = useSWR(
    token ? [process.env.REACT_APP_BACKEND_URL + `/admin/dealers`, token] : null,
    fetcher
  );

  useEffect(() => {
    if (token) {
      fetch(process.env.REACT_APP_BACKEND_URL + '/admin/locations/all', {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
          'x-access-token': token,
          Authorization: 'Bearer ' + token
        }
      }).then(async (data) => {
        setLocations(await data.json());
      });
    }
  }, [token]);

  const newDealer = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/admin/dealer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
        Authorization: 'Bearer ' + token
      }
    }).then(async (data) => {
      mutate();
      const dealer = await data.json();
      setOpen(dealer.id);
    });
  };

  const Location = ({ values, setLocation, placeName, dealer }) => {
    return (
      <TableRow size='small' selected={values.id ? false : true}>
        <TableCell
          component='th'
          scope='row'
          style={{ textDecoration: values.deleted ? 'line-through' : 'unset', color: values.deleted ? 'red' : 'unset' }}
          colSpan={values.name ? 1 : 2}
        >
          {values.name ? (
            values.name
          ) : (
            <Autocomplete
              id='combo-box-demo'
              options={locations
                .filter((location) => {
                  if (location.name) {
                    if (dealer.locations.find((l) => l.id === location.id)) return false;
                    return true;
                  }
                  return false;
                })
                .sort((a, b) => (a.name > b.name ? 1 : -1))}
              getOptionLabel={(option) => option.name}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label='Søk og velg butikk' style={{ width: '100%', maxWidth: 600 }} />
              )}
              onChange={(event, location) => {
                setLocation(
                  { tempId: location.id, name: location.name, postLocation: location.postLocation },
                  placeName
                );
              }}
            />
          )}
        </TableCell>
        {values.name && (
          <TableCell
            style={{
              textDecoration: values.deleted ? 'line-through' : 'unset',
              color: values.deleted ? 'red' : 'unset'
            }}
          >
            {values.postLocation}
          </TableCell>
        )}
        <TableCell align='right'>
          {values.deleted ? (
            <IconButton
              color='secondary'
              aria-label='delete'
              style={{ padding: '3px' }}
              onClick={() => {
                setLocation({ ...values, deleted: false }, placeName);
              }}
            >
              <UndoIcon fontSize='small' />
            </IconButton>
          ) : (
            <IconButton
              aria-label='delete'
              style={{ padding: '3px' }}
              onClick={() => {
                setLocation({ ...values, deleted: true }, placeName);
              }}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };
  // const Place = ({ values, setPlaceLocation }) => {
  //   const [open, setOpen] = useState(false);
  //   return (
  //     <>
  //       <TableRow size='small'>
  //         <TableCell style={{ width: '30px' }}>
  //           <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
  //             {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
  //           </IconButton>
  //         </TableCell>
  //         <TableCell component='th' scope='row' colSpan={2}>
  //           {values.name}
  //         </TableCell>
  //         <TableCell align='right'>
  //           {values.deleted && (
  //             <IconButton color='secondary' aria-label='delete' style={{ padding: '3px' }}>
  //               <UndoIcon fontSize='small' />
  //             </IconButton>
  //           )}
  //           {!values.deleted && (
  //             <IconButton aria-label='delete' style={{ padding: '3px' }}>
  //               <DeleteIcon fontSize='small' />
  //             </IconButton>
  //           )}
  //         </TableCell>
  //       </TableRow>
  //       <TableRow>
  //         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
  //           <Collapse in={open}>
  //             <Table size='small' style={{ backgroundColor: theme.palette.primary.light }}>
  //               <TableBody>
  //                 {values.locations.map((data) => (
  //                   <Location
  //                     key={data.name}
  //                     values={data}
  //                     hideId={true}
  //                     setLocation={setPlaceLocation}
  //                     placeName={values.name}
  //                   />
  //                 ))}
  //                 <TableRow size='small'>
  //                   <TableCell
  //                     component='th'
  //                     scope='row'
  //                     colSpan={3}
  //                     align='center'
  //                     style={{ backgroundColor: 'unset' }}
  //                   >
  //                     <IconButton aria-label='Add' style={{ backgroundColor: theme.palette.secondary.dark }}>
  //                       <AddIcon />
  //                     </IconButton>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableBody>
  //             </Table>
  //           </Collapse>
  //         </TableCell>
  //       </TableRow>
  //     </>
  //   );
  // };

  const Details = (props) => {
    const { values, open, setOpen } = props;
    const [dealer, setDealer] = useState(values);
    const [save, setSave] = useState(false);

    const [colors, setColors] = useState({
      primary: {
        main: '#ccc',
        light: '#ccc'
      },
      secondary: {
        main: '#ccc',
        light: '#ccc'
      }
    });

    useEffect(() => {
      if (dealer.name !== values.name) setSave(true);
      else if (dealer.locations.find((l) => l.deleted && l.id)) setSave(true);
      else if (dealer.locations.find((l) => !l.id && l.name && !l.deleted)) setSave(true);
      else setSave(false);
      dealer.theme && setColors(dealer.theme);
    }, [dealer, values]);

    const saveDealer = () => {
      const data = {
        ...dealer,
        theme: colors,
        locations: [...dealer.locations].filter((l) => !l.deleted).map((l) => (l.tempId ? l.tempId : l.id))
      };
      console.log(data);

      fetch(process.env.REACT_APP_BACKEND_URL + '/admin/dealer/locationsandplaces', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(async (data) => {
        setDealer(await data.json());
        mutate();
      });
    };

    const setEnabled = (event) => {
      const data = {
        ...dealer,
        enabled: event.target.checked
      };

      fetch(process.env.REACT_APP_BACKEND_URL + '/admin/dealer/enable', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(async (data) => {
        console.log(data);
        // setDealer((dealer) => {
        //   return {
        //     ...dealer,
        //     enabled: event.target.checked
        //   };
        // });
      });
    };

    const setLocation = (newLocation) => {
      const index = dealer.locations.findIndex((location) => location.id === newLocation.id);
      if (index >= 0) {
        const newDealer = { ...dealer };
        newDealer.locations[index] = newLocation;
        setDealer(newDealer);
      } else {
        setDealer({ ...dealer, locations: [...dealer.locations, newLocation] });
      }
    };
    const setPlaceLocation = (newLocation, placeName) => {
      const newDealer = { ...dealer };

      const placeIndex = dealer.places.findIndex((place) => place.name === placeName);
      const index = dealer.places[placeIndex].locations.findIndex((location) => location.id === newLocation.id);
      if (index >= 0) {
        newDealer.places[placeIndex].locations[index] = newLocation;

        setDealer(newDealer);
      } else {
        newDealer.places[placeIndex].locations = [...newDealer.places[placeIndex].locations, newLocation];
        setDealer(newDealer);
      }
    };

    const onChange = (event) => {
      setDealer((dealer) => {
        return {
          ...dealer,
          [event.target.id]: event.target.value
        };
      });
    };

    const ColorPicker = ({ level1, level2 }) => {
      const popover = useRef();
      const [isOpen, setOpen] = useState(false);

      const [color, setColor] = useState(colors[level1][level2]);

      const close = useCallback(() => {
        console.log('close');
        setColors((colors) => colors);
        setOpen(false);
      }, []);
      useClickOutside(popover, close);

      const onChange = (color) => {
        setColor(color);
      };

      const handleOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

      const saveColor = () => {
        setColors((colors) => {
          colors[level1][level2] = color;
          return colors;
        });
        setOpen(false);
        setSave(true);
      };

      return (
        <>
          <div
            className='swatch'
            style={{
              backgroundColor: color,
              height: '20px',
              width: '20px',
              margin: '20px',
              border: '1px solid',
              padding: '20px',
              boxShadow: '0 0 20px'
            }}
            // onClick={() => setOpen(true)}
            onClick={handleOpen}
          />

          {/* <Modal
  open={isOpen}
  onClose={handleClose}
  // aria-labelledby="simple-modal-title"
  // aria-describedby="simple-modal-description"
>
<HexColorPicker color={color} onChange={onChange} />
          <HexColorInput color={color} onChange={onChange} />
          <Button variant="contained" color="primary" onClick={saveColor}>OK</Button>
</Modal> */}

          {isOpen && (
            <div
              className='popover'
              ref={popover}
              style={{ margin: '20px', border: '1px solid', padding: '20px', boxShadow: '0 0 20px' }}
            >
              <HexColorPicker color={color} onChange={onChange} />
              <HexColorInput color={color} onChange={onChange} />
              <Button variant='contained' color='primary' onClick={saveColor}>
                OK
              </Button>
            </div>
          )}
        </>
      );
    };

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            {/* <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}> */}
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => (open === dealer.id ? setOpen(null) : setOpen(dealer.id))}
            >
              {open === dealer.id ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            {dealer.name}
          </TableCell>
          <TableCell align='center'>{dealer.locations.length}</TableCell>
          <TableCell align='center'>{dealer.places.length}</TableCell>
          <TableCell align='right'>
            <Switch
              checked={dealer.enabled}
              onChange={setEnabled}
              // inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: theme.palette.primary.light }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open === dealer.id}>
              <Box sx={{ margin: 1 }}>
                {/* <Typography variant='h6' gutterBottom component='div'>
                    Forhandler
                  </Typography> */}

                <Grid container justifyContent='space-between' spacing={5}>
                  <Grid item xs={6}>
                    <TextField id='name' label='Lagre som' value={dealer.name} onChange={onChange} />
                  </Grid>
                  <Grid item xs={6} align='right'>
                    <Button
                      align='right'
                      variant='contained'
                      color='primary'
                      size='large'
                      disabled={!save}
                      sx={{ marginTop: '20px', marginRight: '10px' }}
                      startIcon={<SaveIcon />}
                      onClick={saveDealer}
                    >
                      Lagre
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <ColorPicker level1={'primary'} level2={'main'} />
                    <ColorPicker level1={'primary'} level2={'light'} />
                    <ColorPicker level1={'secondary'} level2={'main'} />
                    <ColorPicker level1={'secondary'} level2={'light'} />
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ marginBottom: '50px' }}>
                      <Toolbar size='small' variant='dense'>
                        <Typography>Triolink-butikker</Typography>
                      </Toolbar>
                      <Table size='small'>
                        <TableBody>
                          {dealer.locations
                            .filter((location) => !location.deleted || location.id)
                            .map((data) => (
                              <Location key={data.id} values={data} setLocation={setLocation} dealer={dealer} />
                            ))}

                          <TableRow size='small'>
                            <TableCell component='th' scope='row' colSpan={3} align='center'>
                              <IconButton
                                aria-label='Add'
                                size='medium'
                                style={{ backgroundColor: theme.palette.secondary.light }}
                                onClick={() => {
                                  setLocation({});
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ marginBottom: '50px' }}>
                      <Toolbar size='small' variant='dense'>
                        <Typography>Områder</Typography>
                      </Toolbar>
                      <Table size='small'>
                        <TableBody>
                          {dealer.places.map((data) => (
                            <Place key={data.name} values={data} setPlaceLocation={setPlaceLocation} />
                          ))}
                          <TableRow size='small'>
                            <TableCell component='th' scope='row' colSpan={4} align='center'>
                              <IconButton
                                aria-label='Add'
                                size='medium'
                                style={{ backgroundColor: theme.palette.secondary.light }}
                              >
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid> */}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return dealers ? (
    <>
      <IconButton onClick={newDealer} aria-label='Add' style={{ backgroundColor: theme.palette.primary.light }}>
        <AddIcon />
      </IconButton>
      <TableContainer component={Paper} style={{ overflow: 'visible' }}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Forhandler</TableCell>
              <TableCell align='center'>Butikker</TableCell>
              <TableCell align='center'>Områder</TableCell>
              <TableCell align='right'>Aktiv</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealers &&
              dealers.length &&
              dealers
                .sort((a, b) => (!b.name || a.name > b.name ? 1 : -1))
                .map((dealer) => {
                  dealer.enabled = !!dealer.enabled || false;
                  // console.log(data.dealer.id, showDetails, showDetails ? showDetails.id === data.dealer.id : false);
                  return <Details key={dealer.id} values={dealer} open={open} setOpen={setOpen} />;
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <LinearProgress />
  );
};

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener('mousedown', validateEventStart);
    document.addEventListener('touchstart', validateEventStart);
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('mousedown', validateEventStart);
      document.removeEventListener('touchstart', validateEventStart);
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
};
