/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { TextField, Button, Link, Paper, Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { fetcher } from '../api/fetcher';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
export const Login = ({ setToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const [resetPassword, setResetPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (disabled) {
      return false;
    } else {
      setDisabled(true);
    }

    if (resetPassword) {
      const reset = await resetUserPassword({
        email
      });
      if (!reset.reset) {
        enqueueSnackbar('Fant ikke bruker med denne e-postadressen', { variant: 'error', preventDuplicate: true });
        return;
      }
      enqueueSnackbar('En e-post med link for å nullstille passord er sendt.', {
        variant: 'success',
        preventDuplicate: true
      });
    } else {
      if (!(email && password)) {
        enqueueSnackbar('Mangler brukernavn eller passord', { variant: 'error', preventDuplicate: true });
        return;
      }
      const login = await loginUser({
        email,
        password
      });
      if (!login.token) {
        enqueueSnackbar('Feil brukernavn eller passord', { variant: 'error', preventDuplicate: true });
        return;
      }
      setToken(login.token);
    }
  };

  return (
    <Box display='flex' minWidth='100vw' justifyContent='center' alignItems='center' minHeight='100vh'>
      <Paper sx={{ width: '400px', minHeight: '200px', textAlign: 'center', padding: '30px' }} elevation={3}>
        <form
          onSubmit={submit}
          // style={{ padding: '20px', maxWidth: '300px', margin: 'auto' }}
        >
          <TextField
            margin='normal'
            // sx={{ width: '300px', marginBottom: '20px' }}
            fullWidth
            size='small'
            id='email'
            label='E-post'
            onChange={(e) => setUserName(e.target.value)}
          />

          {!resetPassword && (
            <TextField
              margin='normal'
              fullWidth
              // sx={{ width: '300px' }}
              size='small'
              id='password'
              label='Passord'
              type='password'
              autoComplete='current-password'
              onChange={(e) => setPassword(e.target.value)}
            />
          )}

          <Button
            disabled={disabled}
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
            sx={{ marginTop: '15px' }}
          >
            {resetPassword ? 'Reset passord' : 'Logg inn'}
          </Button>
        </form>
        <Link
          mt={5}
          display='block'
          href='#'
          onClick={() => {
            setDisabled(false);
            setResetPassword(!resetPassword);
          }}
        >
          {resetPassword ? 'Logg inn?' : 'Glemt passord?'}
        </Link>
      </Paper>
    </Box>
  );
};

export const ResetPassword = ({ token, id, setToken }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const { data: validToken, isValidating } = useSWR(
    token ? process.env.REACT_APP_BACKEND_URL + `/admin/valid-password-request/${token}/${id}` : null,
    fetcher
  );

  const submit = async (e) => {
    e.preventDefault();

    const set = await setUserPassword({
      password,
      token,
      id
    });
    if (!set.valid) {
      enqueueSnackbar('Kunne ikke lagre passord', { variant: 'error', preventDuplicate: true });
      return;
    }
    enqueueSnackbar('Passordet er oppdater.', {
      variant: 'success',
      preventDuplicate: true
    });
    setToken(set.token);
    history.push(`/admin`);
  };

  return (
    <Box display='flex' minWidth='100vw' justifyContent='center' alignItems='center' minHeight='100vh'>
      {isValidating ? (
        <LinearProgress />
      ) : validToken.valid ? (
        <Paper sx={{ width: '400px', minHeight: '200px', textAlign: 'center', padding: '30px' }} elevation={3}>
          <form
            onSubmit={submit}
            // style={{ padding: '20px', maxWidth: '300px', margin: 'auto' }}
          >
            <TextField
              autoFocus
              margin='normal'
              // sx={{ width: '300px', marginBottom: '20px' }}
              fullWidth
              size='small'
              id='password'
              label='Passord'
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              error={confirmPassword?.length >= 6 && password !== confirmPassword}
              helperText={confirmPassword?.length >= 6 && password !== confirmPassword ? 'Stemmer ikke overens' : null}
              margin='normal'
              fullWidth
              size='small'
              id='confirmPassword'
              type='password'
              label='Gjenta passord'
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <Button
              disabled={!password || password?.length < 6 || password !== confirmPassword}
              fullWidth
              variant='contained'
              color='primary'
              type='submit'
              sx={{ marginTop: '15px' }}
            >
              Lagre nytt passord
            </Button>
          </form>
          <Link mt={5} display='block' href='/admin'>
            Avbryt
          </Link>
        </Paper>
      ) : (
        <>404: Page not found</>
      )}
    </Box>
  );
};

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_BACKEND_URL + '/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then((data) => {
      if (data.status === 200) {
        return data.json();
      } else {
        return data;
      }
    })
    .catch((reason) => {
      return reason;
    });
}

async function resetUserPassword(email) {
  return fetch(process.env.REACT_APP_BACKEND_URL + '/admin/reset-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(email)
  })
    .then((data) => {
      if (data.status === 200) {
        return data.json();
      } else {
        return data;
      }
    })
    .catch((reason) => {
      return reason;
    });
}
async function setUserPassword(data) {
  return fetch(process.env.REACT_APP_BACKEND_URL + '/admin/set-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((data) => {
      if (data.status === 200) {
        return data.json();
      } else {
        return data;
      }
    })
    .catch((reason) => {
      return reason;
    });
}
