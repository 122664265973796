const getKjoretoy = async ({ regNum, token }) => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/admin/kjoretoydata', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({ regNum: regNum })
    })
      .then(async (data) => {
        resolve(await data.json());
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { getKjoretoy };
