import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Slider,
  FormControl
} from '@mui/material';
import { calculateApr } from '@knockaway/loan-calculator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Finance = ({ car, finance }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const values = {
      equity: car && car.price ? Math.ceil(car.price * 0.35) : 0,
      equityPercentage: 35,
      years: 8,
      interest: finance?.interest1,
      monthly: 0,
      tinglysing: finance?.tinglysing ? finance.tinglysing : 1051,
      loantotal: 0,
      etablering: finance?.etablering ? finance.etablering : 3279,
      interestTotal: 0,
      termingebyr: finance?.termingebyr ? finance.termingebyr : 95,
      totalPayment: 0
    };

    if (
      finance.equityPercentage > 0 &&
      finance.equityPercentage < 100 &&
      finance.interest2 &&
      finance.equityPercentage > values.equityPercentage
    ) {
      values.interest = finance.interest2;
    }

    setValues(values);
  }, [finance, car]);

  useEffect(() => {
    if (values.interest) {
      const principal = car.price - values.equity + values.etablering + values.tinglysing;
      const interest = values.interest / 100 / 12;

      const termins = values.years * 12;

      const x = Math.pow(1 + interest, termins); //Math.pow computes powers
      const monthly = Math.ceil((principal * x * interest) / (x - 1)) + values.termingebyr;

      const totalPayment = monthly * values.years * 12;

      const apr = calculateApr({
        loanAmount: principal,
        interestRate: values.interest / 100,
        numberOfPayments: termins,
        paymentFrequency: 12,
        compoundingFrequency: 12,
        financedFees: termins * 60,
        upfrontFees: values.etablering + values.tinglysing
      });

      setValues((values) => ({
        ...values,
        loantotal: principal,
        totalPayment: totalPayment,
        monthly: monthly,
        interestTotal: (apr * 100).toFixed(2)
      }));
    }
  }, [
    values.interest,
    values.years,
    car.price,
    values.tinglysing,
    values.etablering,
    values.termingebyr,
    values.equity
  ]);

  const handleEquityChange = (event) => {
    const equity = event.target.value ? (event.target.value < car.price ? event.target.value : car.price) : null;

    const equityPercentage = Math.ceil((event.target.value / car.price) * 100);

    let interest = values.interest;
    if (
      finance.equityPercentage > 0 &&
      finance.equityPercentage < 100 &&
      finance.interest2 &&
      finance.equityPercentage > equityPercentage
    ) {
      interest = finance.interest2;
    } else {
      interest = finance.interest1;
    }

    setValues({
      ...values,
      interest: interest,
      equity: equity,
      equityPercentage: equityPercentage
    });
  };

  const handleYearChange = (event) => {
    const years = event.target.value ? (event.target.value < 10 ? event.target.value : 10) : null;
    setValues({
      ...values,
      years: years
    });
  };

  const handleEquitySlide = (_, value) => {
    const equity = Math.ceil((value / 100) * car.price);

    let interest = values.interest;

    if (
      finance.equityPercentage > 0 &&
      finance.equityPercentage < 100 &&
      finance.interest2 &&
      finance.equityPercentage > value
    ) {
      interest = finance.interest2;
    } else {
      interest = finance.interest1;
    }

    setValues({
      ...values,
      interest: interest,
      equity: equity,
      equityPercentage: value
    });
  };
  const handleYearSlide = (_, value) => {
    setValues({
      ...values,
      years: value
    });
  };

  if (!values.interest) {
    return null;
  }

  return (
    <Box style={{ padding: '50px 20px 50px' }}>
      <Typography color='primary' variant='h5' style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>
        Betaling
      </Typography>
      <Typography variant='h5' style={{}}>
        {values.interest} % nom. rente
      </Typography>
      Pris
      <Typography color='primary' variant='h3' style={{ fontWeight: 'bold', marginTop: '0' }}>
        <span style={{ fontSize: '20px' }}>Kr</span>{' '}
        {/* {(car.price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},-{' '} */}
        {values?.monthly?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},-
        <span style={{ fontSize: '20px' }}>pr måned</span>
      </Typography>
      <Accordion
        sx={{
          border: '1px solid rgba(0, 0, 0, .125)',
          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&:before': {
            display: 'none'
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='panel1a-header'>
          <Typography>Finansieringsdetaljer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <FormControl noValidate autoComplete='off' fullWidth>
                <TextField
                  size='small'
                  label='Egenandel'
                  value={values.equity}
                  onChange={handleEquityChange}
                  name='equity'
                  id='equity-input'
                  InputProps={{
                    //inputComponent: (props) => <NumberFormatCustom prefix='Kr ' {...props} />
                    inputComponent: EquityInput
                  }}
                />
                <Slider
                  value={values?.equityPercentage}
                  onChange={handleEquitySlide}
                  name='equity'
                  aria-labelledby='input-slider'
                  valueLabelDisplay='auto'
                  valueLabelFormat={(x) => x + '%'}
                  sx={{ marginBottom: '10px' }}
                />

                <TextField
                  size='small'
                  label='Nedbetalingstid'
                  value={values?.years}
                  onChange={handleYearChange}
                  name='years'
                  id='year-input'
                  InputProps={{
                    inputComponent: YearsInput
                  }}
                />
                <Slider
                  value={values.years}
                  onChange={handleYearSlide}
                  name='years'
                  aria-labelledby='input-slider-years'
                  valueLabelDisplay='off'
                  sx={{ marginBottom: '10px' }}
                  min={1}
                  max={10}
                />
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                Etablering/tinglysing
              </Grid>
              <Grid item xs={4}>
                kr {(values.tinglysing + values.etablering).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </Grid>

              {/* <Grid item xs={8}>
                Tinglysingsgebyr
              </Grid>
              <Grid item xs={4}>
                kr {values.tinglysing.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </Grid>

              <Grid item xs={8}>
                Etableringsgebyr
              </Grid>
              <Grid item xs={4}>
                kr {values.etablering.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </Grid> */}

              <Grid item xs={8}>
                Lånebeløp
              </Grid>
              <Grid item xs={4}>
                Kr {values?.loantotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </Grid>

              <Grid item xs={8}>
                Termingebyr
              </Grid>
              <Grid item xs={4}>
                Kr {values?.termingebyr}
              </Grid>

              <Grid item xs={8}>
                Kredittkostnader
              </Grid>
              <Grid item xs={4}>
                Kr{' '}
                {Math.ceil(values?.totalPayment - values?.loantotal)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </Grid>

              <Grid item xs={8}>
                Total kostnad
              </Grid>
              <Grid item xs={4}>
                Kr {values?.totalPayment?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </Grid>

              <Grid item xs={8}>
                Effektiv rente
              </Grid>
              <Grid item xs={4}>
                {values?.interestTotal}%
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const EquityInput = (props) => <NumberFormatCustom prefix='Kr ' {...props} />;
const YearsInput = (props) => <NumberFormatCustom suffix=' år' {...props} />;

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, prefix, suffix, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      allowNegative={false}
      thousandSeparator={' '}
      isNumericString
      prefix={prefix ? prefix : ''}
      suffix={suffix ? suffix : ''}
    />
  );
};
