import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './carousel.css';
import ReactGA from 'react-ga';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Link,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  // FormControlLabel,
  // Switch,
  // Collapse,
  MenuItem
} from '@mui/material';

export const Contact = (props) => {
  const { name, email, mobile, phone, location, regNum, carid, carbuddii, car, avatarUrl } = props;
  const [contact, setContact] = useState({});
  const [open, setOpen] = React.useState(false);

  const isAuto88 = (email && email?.includes('auto8-8')) || location?.name?.includes('Auto 8-8');

  useEffect(() => {
    setContact({
      name: name,
      email: email
    });

    if (email)
      fetch(process.env.REACT_APP_BACKEND_URL + '/contact/' + email).then((res) => {
        res.json().then((contact) => {
          if (contact) {
            let mobile = ('' + contact.mobile).replace(/\D/g, '');
            const match = mobile.match(/^(\d{3})(\d{2})(\d{3})$/);
            if (match) {
              contact.mobile = +match[1] + ' ' + match[2] + ' ' + match[3];
            }
            setContact(contact);
          }
        });
      });
  }, [email, name, location]);

  const sendMessage = (type, message) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/' + type, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(message)
    }).then(() => setOpen(false));
  };

  return (
    <Box style={{ padding: '70px 0px 0' }}>
      <Card elevation={0}>
        <CardContent>
          <Typography color='primary' variant='h4' style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Kontaktinfo
          </Typography>
          {contact && contact.image ? (
            <img
              alt={contact.name}
              src={`/images/${contact.image}`}
              width='100%'
              style={{ maxWidth: '200px', marginBottom: '10px' }}
            />
          ) : (
            avatarUrl && (
              <img
                alt={contact.name}
                src={avatarUrl}
                width='100%'
                style={{ maxWidth: '200px', marginBottom: '10px' }}
              />
            )
          )}
          <Typography
            color='primary'
            as='p'
            style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: '10px' }}
          >
            {contact && contact.name}
          </Typography>
          <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
            {contact && contact.title}
          </Typography>
          {phone && (
            <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
              <Link href={'tel:' + phone}>Telefon: {phone}</Link>
            </Typography>
          )}
          <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
            {contact.mobile ? (
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: 'Click',
                    action: 'Mobile',
                    label: JSON.stringify([regNum, carid])
                  });
                }}
                href={'tel:' + contact.mobile}
              >
                Mobil: {contact.mobile}
              </Link>
            ) : mobile ? (
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: 'Click',
                    action: 'Mobile',
                    label: JSON.stringify([regNum, carid])
                  });
                }}
                href={'tel:' + mobile}
              >
                Mobil: {mobile}
              </Link>
            ) : (
              ''
            )}
          </Typography>
          {carbuddii ? (
            <CarBuddii {...props} open={open} setOpen={setOpen} sendMessage={sendMessage} />
          ) : location._id === '60f5c526740284caab3f72a5' ? (
            <Skjema {...props} open={open} setOpen={setOpen} sendMessage={sendMessage} car={car} />
          ) : isAuto88 ? (
            <SkjemaAuto88 {...props} open={open} setOpen={setOpen} sendMessage={sendMessage} car={car} carid={carid} />
          ) : (
            <Button
              variant='contained'
              disableElevation
              sx={{
                borderRadius: '30px',
                padding: '10px 50px',
                '&:hover': {
                  color: '#fff'
                }
              }}
              color='primary'
              href={`mailto:${
                location._id === '60f5c526740284caab3f7280'
                  ? 'alesundbruktbil_tingh@alesundgs.net?cc=roar.maseide@auto8-8.no'
                  : location && location.email
                  ? location.email
                  : contact.email
              }`}
              target='_blank'
              style={{ marginTop: '10px', marginBottom: '20px', fontWeight: 'bold' }}
              onClick={() => {
                ReactGA.event({
                  category: 'Click',
                  action: 'Kontakt oss',
                  label: JSON.stringify([regNum, carid])
                });
              }}
            >
              Kontakt oss
            </Button>
          )}
          {regNum && (
            <>
              <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
                <Link
                  target='_blank'
                  rel='noopener'
                  href={
                    'https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger?registreringsnummer=' +
                    regNum
                  }
                  onClick={() => {
                    ReactGA.event({
                      category: 'Click',
                      action: 'EU-kontroll',
                      label: JSON.stringify([regNum, carid])
                    });
                  }}
                >
                  Sjekk tid for neste EU-kontroll
                </Link>
              </Typography>
              <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
                <Link
                  target='_blank'
                  rel='noopener'
                  href={'http://w2.brreg.no/motorvogn/heftelser_motorvogn.jsp?regnr=' + regNum}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Click',
                      action: 'Heftelser',
                      label: JSON.stringify([regNum, carid])
                    });
                  }}
                >
                  {'Sjekk heftelser på ' + regNum}
                </Link>
              </Typography>{' '}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

const Skjema = (props) => {
  const { location, regNum, carid, contact, open, setOpen, sendMessage, car } = props;

  const [contactName, setName] = useState();
  const [contactEmail, setEmail] = useState();
  const [contactPhone, setPhone] = useState();
  const [message, setMessage] = useState();

  const [contactForm, setContactForm] = useState();

  const schemaMessage = async (e) => {
    e.preventDefault();
    sendMessage('schema', {
      contactEmail,
      contactName,
      contactPhone,
      carid,
      message,
      contactForm
    });
    ReactGA.event({
      category: 'Skjema',
      action: 'Send',
      label: JSON.stringify([carid])
    });
  };
  return (
    <>
      <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
        {location && location.email ? (
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Mail',
                label: JSON.stringify([regNum, carid])
              });
            }}
            href={`mailto:${location.email}`}
          >
            E-post: {location.email}
          </Link>
        ) : contact && contact.email ? (
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Mail',
                label: JSON.stringify([regNum, carid])
              });
            }}
            href={`mailto:${contact.email}`}
          >
            E-post: {contact.email}
          </Link>
        ) : (
          ''
        )}
      </Typography>

      <Button
        variant='contained'
        disableElevation
        sx={{
          borderRadius: '30px',
          padding: '10px 50px',
          '&:hover': {
            color: '#fff'
          }
        }}
        color='primary'
        style={{ marginTop: '10px', marginBottom: '20px', fontWeight: 'bold' }}
        onClick={() => setOpen(true)}
      >
        Kontakt oss
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={schemaMessage}>
          <DialogTitle>
            {car.make} {car.model} {car.yearModel}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Ønsker du å avtale prøvekjøring eller har spørsmål om bilen?</DialogContentText>
            <TextField
              variant='outlined'
              required
              autoFocus
              margin='dense'
              id='name'
              label='Ditt navn'
              type='text'
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='dense'
              id='message'
              label='Melding'
              type='text'
              fullWidth
              rows={4}
              multiline
              onChange={(e) => setMessage(e.target.value)}
            />
            <TextField
              required
              variant='outlined'
              margin='dense'
              id='email'
              label='E-post'
              type='email'
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              variant='outlined'
              inputProps={{ min: 12345678, max: 99999999 }}
              required
              type='number'
              margin='dense'
              id='phone'
              label='Telefon'
              fullWidth
              onChange={(e) => setPhone(e.target.value)}
            />

            <TextField
              select
              variant='outlined'
              required
              margin='dense'
              id='contactForm'
              label='Hvordan vil du bli kontaktet?'
              fullWidth
              onChange={(e) => setContactForm(e.target.value)}
            >
              <MenuItem key={1} value='Telefon'>
                Telefon
              </MenuItem>
              <MenuItem key={2} value='E-post'>
                E-post
              </MenuItem>
            </TextField>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpen(false)}>Avbryt</Button>
            <Button type='submit' color='primary' variant='contained'>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const SkjemaAuto88 = (props) => {
  const { location, contact, open, setOpen, sendMessage, car, carid } = props;

  const [contactName, setName] = useState();
  const [contactEmail, setEmail] = useState();
  const [contactPhone, setPhone] = useState();
  const [message, setMessage] = useState();

  const schemaMessage = async (e) => {
    e.preventDefault();
    sendMessage('schemaAuto88', {
      contactEmail,
      contactName,
      contactPhone,
      carid,
      message,
      make: car.make,
      model: car.model,
      regnum: car.regNum
    });
    ReactGA.event({
      category: 'Skjema',
      action: 'Send',
      label: JSON.stringify([carid])
    });
  };
  return (
    <>
      <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
        {location && location.email ? (
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Mail',
                label: JSON.stringify([car.regNum, carid])
              });
            }}
            href={`mailto:${location.email}`}
          >
            E-post: {location.email}
          </Link>
        ) : contact && contact.email ? (
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Mail',
                label: JSON.stringify([car.regNum, carid])
              });
            }}
            href={`mailto:${contact.email}`}
          >
            E-post: {contact.email}
          </Link>
        ) : (
          ''
        )}
      </Typography>

      <Button
        variant='contained'
        disableElevation
        sx={{
          borderRadius: '30px',
          padding: '10px 50px',
          '&:hover': {
            color: '#fff'
          }
        }}
        color='primary'
        style={{ marginTop: '10px', marginBottom: '20px', fontWeight: 'bold' }}
        onClick={() => setOpen(true)}
      >
        Kontakt oss
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={schemaMessage}>
          <DialogTitle>
            Ta kontakt om du har spørsmål eller ønsker å avtale prøvekjøring av{' '}
            <i>
              {car.make} {car.model} ({car.yearModel})
            </i>
            .
          </DialogTitle>
          <DialogContent>
            <TextField
              variant='outlined'
              required
              autoFocus
              margin='dense'
              id='name'
              label='Ditt navn'
              type='text'
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              required
              variant='outlined'
              margin='dense'
              id='email'
              label='E-post'
              type='email'
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              variant='outlined'
              inputProps={{ min: 12345678, max: 99999999 }}
              required
              type='number'
              margin='dense'
              id='phone'
              label='Telefon'
              fullWidth
              onChange={(e) => setPhone(e.target.value)}
            />

            <TextField
              variant='outlined'
              margin='dense'
              id='message'
              label='Melding'
              type='text'
              fullWidth
              rows={4}
              multiline
              onChange={(e) => setMessage(e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpen(false)}>Avbryt</Button>
            <Button type='submit' color='primary' variant='contained'>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const CarBuddii = (props) => {
  const { location, regNum, carid, contact, open, setOpen, sendMessage } = props;
  // const [innbytte, setInnbytte] = useState(false);
  const [contactName, setName] = useState();
  const [contactEmail, setEmail] = useState();
  const [contactPhone, setPhone] = useState();
  const [message, setMessage] = useState();

  const carbuddiiMessage = async (e) => {
    e.preventDefault();
    sendMessage('carbuddii', {
      contactEmail,
      contactName,
      contactPhone,
      carid,
      message
    });
    ReactGA.event({
      category: 'CarBuddii',
      action: 'Send',
      label: JSON.stringify([carid])
    });
  };

  return (
    <>
      <Typography color='textSecondary' style={{ marginBottom: '10px' }}>
        {location && location.email ? (
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Mail',
                label: JSON.stringify([regNum, carid])
              });
            }}
            href={`mailto:${location.email}`}
          >
            E-post: {location.email}
          </Link>
        ) : contact && contact.email ? (
          <Link
            onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Mail',
                label: JSON.stringify([regNum, carid])
              });
            }}
            href={`mailto:${contact.email}`}
          >
            E-post: {contact.email}
          </Link>
        ) : (
          ''
        )}
      </Typography>

      <Button
        variant='contained'
        disableElevation
        sx={{
          borderRadius: '30px',
          padding: '10px 50px',
          '&:hover': {
            color: '#fff'
          }
        }}
        color='primary'
        style={{ marginTop: '10px', marginBottom: '20px', fontWeight: 'bold' }}
        onClick={() => setOpen(true)}
      >
        Kontakt oss
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={carbuddiiMessage} className='form'>
          <DialogTitle>Kontakt oss</DialogTitle>
          <DialogContent>
            <DialogContentText>Ønsker du å avtale prøvekjøring eller har spørsmål om bilen?</DialogContentText>
            <TextField
              required
              autoFocus
              margin='dense'
              id='name'
              label='Navn'
              type='text'
              fullWidth
              variant='outlined'
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin='dense'
              id='message'
              label='Melding'
              type='text'
              fullWidth
              rows={4}
              variant='outlined'
              multiline
              onChange={(e) => setMessage(e.target.value)}
            />
            <TextField
              required
              margin='dense'
              id='email'
              label='E-post'
              type='email'
              fullWidth
              variant='outlined'
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              required
              margin='dense'
              id='phone'
              label='Telefon'
              fullWidth
              variant='outlined'
              onChange={(e) => setPhone(e.target.value)}
            />
            {/* <div style={{ marginTop: '20px' }}>Har du innbyttebil?</div>
            <FormControlLabel
              margin='dense'
              fullWidth
              control={
                <Switch
                  checked={innbytte}
                  color='primary'
                  onClick={() => {
                    setInnbytte(!innbytte);
                  }}
                />
              }
              label={innbytte ? 'ja' : 'nei'}
            />
            <Collapse in={innbytte}>
              <TextField
                required
                margin='dense'
                id='regnr'
                label='Registreringsnummer'
                fullWidth
                variant='outlined'
                disabled
              />
            </Collapse> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Avbryt</Button>
            <Button type='submit' color='primary' variant='contained'>
              Send inn
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
