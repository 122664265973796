import React, { useEffect, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ReactGA from 'react-ga';

const RangeWrapper = styled('div')(({ theme }) => ({
  padding: '10px 30px 30px 30px',
  [`& .rangeSlider1`]: {
    transition: 'background-color 2s, color 2s',
    color: 'white',
    position: 'absolute!important',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
    padding: '2px 10px',
    cursor: 'grab',
    whiteSpace: 'nowrap'
  }
}));

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export const RangeFilter = ({ settings, current, setCurrent, label, step }) => {
  // const classes = useStyles();
  const [value, setValue] = useState();
  useEffect(() => {
    setValue([current.from, current.to]);
  }, [setValue, current]);
  const onAfterChange = (value) => {
    setCurrent({ from: value[0], to: value[1] });

    ReactGA.event({
      category: 'Filter',
      action: label,
      label: JSON.stringify(value)
    });
  };
  const onChange = (value) => {
    setValue(value);
  };

  const tipFormatter = (value) => {
    return value > 9999 ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : value;
  };

  // useEffect(() => {
  //   console.log('current');
  // }, [current]);
  const theme = useTheme();
  return (
    // <Root style={wrapperStyle}>
    <RangeWrapper>
      <Typography
        variant='h6'
        color='primary'
        style={{ fontWeight: 'bold', marginBottom: '10px', marginLeft: '-20px' }}
      >
        {label}
      </Typography>
      <Range
        value={value}
        min={settings.from}
        max={settings.to}
        step={step ? step : 1}
        onChange={onChange}
        onAfterChange={onAfterChange}
        trackStyle={[{ backgroundColor: theme.palette.primary.light }]}
        handleStyle={[{ borderColor: theme.palette.primary.light }]}
        tipProps={{ visible: true, placement: 'bottom', prefixCls: 'rangeSlider1' }}
        tipFormatter={tipFormatter}
      />
    </RangeWrapper>
  );
};
