/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { fetcher } from '../../api/fetcher';
import { getKjoretoy } from '../common';
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Grid,
  LinearProgress,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TwoWheeler } from '@mui/icons-material';
import { EditMC } from './MCEdit';
import { useHistory } from 'react-router-dom';

export const MCList = ({ token, dealer, mcId }) => {
  const theme = useTheme();
  const history = useHistory();
  const [vehicle, setVehicle] = useState();
  const [addMcDialogOpen, setAddMcDialogOpen] = useState(false);

  const { data: vehicles, mutate } = useSWR(
    token ? [process.env.REACT_APP_BACKEND_URL + `/admin/motorcycles`, token, dealer?.id] : null,
    fetcher
  );

  useEffect(()=> {
    if(vehicles && mcId && !vehicle) {
      setVehicle(vehicles.find(vehicle => vehicle.id === mcId));
    }
  }, [mcId, vehicle, vehicles])

  const updateVehicle = useCallback(
    (vehicle) => {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/admin/motorcycle', vehicle, {
          headers: { Authorization: 'Bearer ' + token, 'x-access-token': token }
        })
        .then((res) => {
          if (!vehicle.id) setVehicle((vehicle) => ({ ...vehicle, id: res.data.id }));
          mutate();
        })
        .catch(console.error);
    },
    [mutate, token]
  );

  if (!vehicles) return <LinearProgress />;

  return (
    <Grid container spacing={5}>
      {vehicles && !vehicle && (
        <Grid item xs={12}>
          <>
            <IconButton onClick={() => setAddMcDialogOpen(true)} sx={{ backgroundColor: theme.palette.primary.light }}>
              <AddIcon />
            </IconButton>
            <AddMcDialog
              addMcDialogOpen={addMcDialogOpen}
              setAddMcDialogOpen={setAddMcDialogOpen}
              token={token}
              setVehicle={setVehicle}
              dealer={dealer}
              updateVehicle={updateVehicle}
            />
          </>
        </Grid>
      )}
      <Grid item xs={12}>
        {vehicle ? (
          <EditMC vehicle={vehicle} setVehicle={setVehicle} token={token} updateVehicle={updateVehicle} />
        ) : (
          vehicles && (
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>RegNr</TableCell>
                    <TableCell align='left'>Merke</TableCell>
                    <TableCell align='left'>Modell</TableCell>
                    <TableCell align='center'>Aktiv</TableCell>
                    <TableCell align='center'>Solgt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicles &&
                    vehicles
                      .sort(
                        (
                          { mc: { make: makeA, model: modelA, regNum: regNumA } },
                          { mc: { make: makeB, model: modelB, regNum: regNumB } }
                        ) =>
                          makeA.localeCompare(makeB) || modelA.localeCompare(modelB) || regNumA.localeCompare(regNumB)
                      )
                      .map((mc) => (
                        <TableRow
                          sx={{ cursor: 'pointer' }}
                          hover
                          key={mc.id}
                          selected={vehicle?.id && vehicle.id === mc.id}
                          onClick={() => {
                            setVehicle((vehicle) => (vehicle?.id === mc.id ? null : mc));
                            history.push(`/admin/mc/${mc.id}`);
                          }}
                        >
                          <TableCell align='left'>{mc.mc.regNum}</TableCell>
                          <TableCell align='left'>{mc.mc.make}</TableCell>
                          <TableCell align='left'>{mc.mc.model}</TableCell>
                          <TableCell align='center'>{mc.isActive ? 'Ja' : 'Nei'}</TableCell>
                          <TableCell align='center'>{mc.mc.isSold ? 'ja' : 'Nei'}</TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}
      </Grid>
    </Grid>
  );
};

const AddMcDialog = ({ addMcDialogOpen, setAddMcDialogOpen, token, setVehicle, dealer, updateVehicle }) => {
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [kjoretoy, setKjoretoy] = useState();

  useEffect(() => {
    const fetchKjoretoy = async () => {
      setSearching(true);
      const kjoretoy = await getKjoretoy({ token: token, regNum: search });
      setKjoretoy(kjoretoy);
      setSearching(false);
    };
    if (search.length === 6) {
      fetchKjoretoy();
    } else {
      setKjoretoy(null);
    }
  }, [search, token]);

  const newMc = {
    // id: new ObjectID().toHexString(),
    mc: {
      regNum: '',
      vin: '',
      make: '',
      model: '',
      variant: '',
      registrationClass: '',
      fuel: '',
      firstTimeRegistration: '',
      colorMain: '',
      kontrollfrist: '',
      sistGodkjent: ''
    },
    contacts: [],
    location: dealer.locations[0]
  };

  return (
    <Dialog
      open={addMcDialogOpen}
      onClose={() => {
        setAddMcDialogOpen(false);
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Legg til MC</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bruk registreringsnummeret til å hente informasjon fra kjøretøyregisteret.
        </DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          error={search.length > 1 && search.length !== 6}
          helperText={search.length > 1 && search.length !== 6 ? 'Ugyldig nummer' : ''}
          id='name'
          label='Registreringsnummer'
          type='text'
          value={search}
          onChange={(e) => {
            let value = e.target.value;
            value = value.charAt(0).toUpperCase() + value.charAt(1).toUpperCase() + value.slice(2);

            setSearch(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={() => setAddMcDialogOpen(false)} color='secondary' variant='outlined'>
          Avbryt
        </Button> */}
        <Button
          onClick={() => {
            setVehicle({ ...newMc });
            updateVehicle({ ...newMc });
            setAddMcDialogOpen(false);
          }}
          color='primary'
          variant='outlined'
        >
          Start uten informasjon
        </Button>
        <Button
          color='primary'
          disabled={!kjoretoy?.registrering}
          onClick={() => {
            const newValues = { ...newMc, mc: { ...newMc.mc, regNum: search } };

            if (kjoretoy && kjoretoy.godkjenning) {
              newValues.mc.vin = kjoretoy.kjoretoyId?.understellsnummer;
              newValues.mc.firstTimeRegistration =
                kjoretoy.godkjenning.forstegangsGodkjenning?.forstegangRegistrertDato;
              const tekniskeData = kjoretoy?.godkjenning?.tekniskGodkjenning?.tekniskeData;
              newValues.mc.registrationClass =
                kjoretoy?.godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.beskrivelse;
              if (tekniskeData) {
                const { dimensjoner, vekter, generelt, akslinger, karosseriOgLasteplan, miljodata, motorOgDrivverk } =
                  tekniskeData;
                newValues.mc.make = generelt.merke[0].merke;
                newValues.mc.model = generelt.handelsbetegnelse[0];
                if (karosseriOgLasteplan?.rFarge?.length) {
                  newValues.mc.colorMain = karosseriOgLasteplan.rFarge[0].kodeNavn;
                }
                if (miljodata?.miljoOgdrivstoffGruppe?.length) {
                  newValues.mc.fuel = miljodata.miljoOgdrivstoffGruppe[0].drivstoffKodeMiljodata?.kodeNavn;
                } else if (motorOgDrivverk?.motor?.length === 1) {
                  newValues.mc.fuel = motorOgDrivverk.motor[0].drivstoff[0].drivstoffKode.kodeNavn;
                }
                if (motorOgDrivverk?.girkassetype) {
                  newValues.mc.transmission = motorOgDrivverk.girkassetype.kodeNavn;
                }
              }
              if (kjoretoy?.periodiskKjoretoyKontroll?.kontrollfrist) {
                newValues.mc.kontrollfrist = new Date(kjoretoy.periodiskKjoretoyKontroll.kontrollfrist);
              }
              if (kjoretoy?.periodiskKjoretoyKontroll?.sistGodkjent) {
                newValues.mc.sistGodkjent = new Date(kjoretoy.periodiskKjoretoyKontroll.sistGodkjent);
              }
              setVehicle({
                ...newValues
              });
              updateVehicle({ ...newValues });
            }
            setAddMcDialogOpen(false);
          }}
          variant='contained'
          endIcon={<TwoWheeler />}
        >
          Legg til{' '}
          {kjoretoy && kjoretoy.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt
            ? (kjoretoy.godkjenning.tekniskGodkjenning?.tekniskeData?.generelt?.merke[0]?.merke || '') +
              ' ' +
              (kjoretoy.godkjenning.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || '')
            : ''}
        </Button>
      </DialogActions>
      {searching && <LinearProgress />}
    </Dialog>
  );
};
