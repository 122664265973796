/* eslint-disable no-unused-vars */
import React from 'react';
import { List, ListSubheader, Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  People,
  Help,
  AccountBalance,
  ExitToApp,
  BusinessCenter,
  PersonAdd,
  Settings,
  Tune,
  TwoWheeler,
  AdminPanelSettings
} from '@mui/icons-material';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import SnowmobileIcon from '@mui/icons-material/Snowmobile';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

export const Menu = (props) => {
  const { logOut, superUser } = props;

  return (
    <>
      <List subheader={<ListSubheader component='div'>Forhandler</ListSubheader>}>
        <MenuItem id='dealer' text='Forhandler' icon={<BusinessCenter />} {...props} />
        <MenuItem id='users' text='Brukere' icon={<PersonAdd />} {...props} />
      </List>

      <Divider />

      <List subheader={<ListSubheader component='div'>Lager</ListSubheader>}>
        <MenuItem id='cars' text='Biler' icon={<DirectionsCarIcon />} {...props} />
        <MenuItem id='mc' text='Motorsykler' icon={<TwoWheeler />} {...props} />
        <MenuItem id='snowmobile' text='Snøscooter' icon={<SnowmobileIcon />} {...props} disabled />
      </List>

      <List subheader={<ListSubheader component='div'>Online billiste</ListSubheader>}>
        <MenuItem id='listsettings' text='Generelt' icon={<Settings />} {...props} />
        <MenuItem id='filters' text='Søkefilter' icon={<Tune />} {...props} />
        <MenuItem id='finance' text='Bilfinans' icon={<AccountBalance />} {...props} />
        <MenuItem id='sellers' text='Selgere' icon={<People />} {...props} disabled />
      </List>
      {superUser && (
        <>
          <Divider />
          <List subheader={<ListSubheader component='div'>Admin</ListSubheader>}>
            <MenuItem id='dealers' text='Forhandlere' icon={<AdminPanelSettings />} {...props} />
          </List>
          <Divider />
        </>
      )}
      <List>
        <MenuItem id='help' text='Suport' icon={<Help />} {...props} />
        <ListItem button key='exit' id='exit' onClick={logOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary='Logg ut' />
        </ListItem>
      </List>
    </>
  );
};

const MenuItem = (props) => {
  const { menuClick, id, icon, selected, text, disabled } = props;
  return (
    <ListItem disabled={disabled} button key={id} id={id} selected={selected === id} onClick={() => menuClick(id)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};
