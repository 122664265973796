/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../api/fetcher';
import {
  TextField,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  CardActions,
  Button,
  Grid,
  LinearProgress
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const ListSettings = ({ token, dealer, setMutate }) => {
  const [disabled, setDisabled] = useState(false);
  const [url, setUrl] = useState(dealer?.subdomain?.host || '');

  useEffect(() => {
    setUrl(dealer?.subdomain?.host ? dealer.subdomain.host : '');
  }, [dealer]);

  const { data: listsettings, isValidating } = useSWR(
    token ? [process.env.REACT_APP_BACKEND_URL + `/admin/listsettings`, token, dealer._id] : null,
    fetcher
  );

  if (isValidating) return <LinearProgress />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant='h5' component='h2'>
              Link
            </Typography>
            <Typography color='textSecondary'>Opprett subdomene</Typography>
            <TextField
              style={{ width: '400px' }}
              label='subdomene'
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              id='url'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    .bra-auto.no
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          dealer.subdomain?.host
                            ? `http:///${dealer.subdomain?.host}.bra-auto.no`
                            : 'http://bra-auto.no'
                        );
                      }}
                      style={{ marginLeft: '20px' }}
                      startIcon={<ContentCopyIcon />}
                    >
                      Kopier
                    </Button>
                    {/* <IconButton
                      aria-label='toggle password visibility'
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <ContentCopyIcon />
                    </IconButton> */}
                  </InputAdornment>
                )
              }}
            />
            <Typography variant='body2' component='p'>
              Bruk for eksempel <i>{dealer?.name.split(' ')[0].toLowerCase()}.bra-auto.no.</i>
              <br /> PS! Det kan ta 1-30 minutter før endringen trer i kraft.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                fetch(process.env.REACT_APP_BACKEND_URL + '/admin/dealer/subdomain', {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                    Authorization: 'Bearer ' + token
                  },
                  body: JSON.stringify({ host: url, id: dealer._id })
                })
                  .then((data) =>
                    data.json().then((res) => {
                      setMutate(Math.random());
                    })
                  )
                  .catch((reason) => {
                    alert('Kunne ikke oppdatere. Subdomain eksisterer kanskje fra før.');
                  });
              }}
            >
              Lagre
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                window.open('/' + listsettings.dealer);
              }}
              startIcon={<LaunchIcon />}
            >
              Åpne link
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant='h5' component='h2'>
              Import av biler
            </Typography>
            <Typography variant='body2' component='p'>
              Det kan ta noen minutter før bilene blir oppdatert fra Triolink. Start oppdatering manuelt?
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              color='secondary'
              disabled={disabled}
              onClick={(e) => {
                setDisabled(true);
                fetch(process.env.REACT_APP_BACKEND_URL + '/admin/updatefromtriolink', {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'text/plain',
                    'x-access-token': token,
                    Authorization: 'Bearer ' + token
                  }
                })
                  .then((res) => {
                    if (res.status === 401) {
                      return;
                    }
                    res.json().then((res) => {});
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              startIcon={<LaunchIcon />}
            >
              Start import fra Triolink
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
