import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const fields = [
  { label: '1. gang registrert', name: 'firstTimeRegistration', date: true },
  { label: 'Reg.nr', name: 'regNum' },
  { label: 'Chassisnr. (VIN)', name: 'vin' },
  { label: 'Avgiftsklasse', name: 'registrationClass' },
  { label: 'Antall eiere', name: 'numOfOwners' },
  { label: 'Karosseri', name: 'bodyType' },
  { label: 'Antall dører', name: 'numOfDoors' },
  { label: 'Antall seter', name: 'numOfSeats' },
  { label: 'Hjuldrift', name: 'wheelDrive' },
  { label: 'Effekt', name: 'engineHp' },

  { label: 'Batterikapasitet', name: 'batteryCapacity' },
  { label: 'Rekkevidde', name: 'drivingRange' },
  { label: 'Sylindervolum', name: 'volume', tousands: true },
  { label: 'Vekt', name: 'weight', tousands: true },
  { label: 'Str. lasterom', name: 'cargoVolume', tousands: true },
  // { label: 'Maksimal tilhengervekt', name: 'trailer', tousands: true },

  // { label: 'Maksimal tilhengervekt', name: 'trailer', tousands: true },
  // { label: 'Maksimal tilhengervekt', name: 'trailer', tousands: true },
  { label: 'Maksimal nyttelast', name: 'vekter.nyttelast', tousands: true },
  { label: 'Maksimal tilhengervekt', name: 'vekter.tillattTilhengervektMedBrems', tousands: true },
  { label: 'Maksimal taklast', name: 'vekter.tillattTaklast', tousands: true },

  { label: 'Farge', name: 'colorName' },
  { label: 'Fargebeskrivelse', name: 'colorMain' },
  { label: 'Interiørfarge', name: 'interiorColor' }
];

const resolvePath = (object, path, defaultValue) => path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
const getDateString = (inputDate) => {
  const date = new Date(inputDate);

  return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
};
const formatTousands = (inputValue) => {
  return inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const Spesifications = ({ car, small }) => {
  const theme = useTheme();

  // console.log(car);

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const [spesifiications, setSpesifications] = useState([]);
  const [rowLength, setRowLength] = useState(0);

  useEffect(() => {
    if (car) {
      const spesifiications = [];
      fields.forEach((field) => {
        let value = resolvePath(car, field.name);
        if (value) {
          if (field.date) {
            value = getDateString(value);
          }
          if (field.tousands) {
            value = formatTousands(value);
          }
          spesifiications.push({ label: field.label, value: value });
        }
      });
      setSpesifications([...spesifiications]);
    }
  }, [car]);

  useEffect(() => {
    if (xl || (lg && !small)) {
      setRowLength(Math.ceil(spesifiications.length / 4));
    } else if ((md && !small) || lg) {
      setRowLength(Math.ceil(spesifiications.length / 3));
    } else if ((md && !small) || sm) {
      setRowLength(Math.ceil(spesifiications.length / 2));
    } else {
      setRowLength(spesifiications.length);
    }
  }, [sm, md, lg, xl, small, spesifiications]);

  return (
    <Box xs={12} style={{ padding: '20px 20px', backgroundColor: '#f5f4f4', marginTop: '30px' }}>
      <Typography color='primary' variant='h4' style={{ fontWeight: 'bold', margin: '0 0 40px' }}>
        Spesifikasjoner
      </Typography>

      <Grid container>
        {[...Array(rowLength)].map((_, index) => {
          const start = index * rowLength;
          const end = (index + 1) * rowLength;
          const colSpesifications = spesifiications.slice(start, end);
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={small ? 6 : 4}
              lg={small ? 4 : 3}
              xl={3}
              style={{ borderLeft: '1px solid', paddingLeft: '25px' }}
            >
              {colSpesifications.map((spesifiication, index) => {
                return <SpesItem key={index} label={spesifiication.label} value={spesifiication.value} />;
              })}
            </Grid>
          );
        })}
      </Grid>

      {/* <Grid container>
        <Grid
          item
          xs={12}
          sm={small ? 12 : 6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {firstTimeRegistration && <SpesItem label='1. gang registrert' value={firstTimeRegistration} />}
          {car.regNum && <SpesItem label='Reg.nr' value={car.regNum} />}
          {car.vin && <SpesItem label='Chassisnr. (VIN)' value={car.vin} />}
          {car.registrationClass && <SpesItem label='Avgiftsklasse' value={car.registrationClass} />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {car.bodyType && <SpesItem label='Karosseri' value={car.bodyType} />}
          {car.numOfDoors && <SpesItem label='Antall dører' value={car.numOfDoors} />}
          {car.numOfSeats && <SpesItem label='Antall seter' value={car.numOfSeats} />}
          {car.wheelDrive && <SpesItem label='Hjuldrift' value={car.wheelDrive} marginBottom='0' />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {car.engineHp && <SpesItem label='Effekt' value={car.engineHp} />}
          {car.volume && (
            <SpesItem label='Sylindervolum' value={car.volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
          )}
          {car.weight && <SpesItem label='Vekt' value={car.weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />}
          {<SpesItem label='Str. lasterom' value={car.cargoVolume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />}

          {car.trailer && (
            <SpesItem
              label='Maksimal tilhengervekt'
              value={car.trailer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              marginBottom='0'
            />
          )}
          {car.vekter?.tillattTilhengervektMedBrems > 0 && (
            <SpesItem
              label='Maksimal tilhengervekt'
              value={car.vekter.tillattTilhengervektMedBrems.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              marginBottom='0'
            />
          )}
          {car.vekter?.tillattTaklast > 0 && (
            <SpesItem
              label='Maksimal taklast'
              value={car.vekter.tillattTaklast.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              marginBottom='0'
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {car.colorName && <SpesItem label='Farge' value={car.colorName} />}
          {car.colorMain && <SpesItem label='Fargebeskrivelse' value={car.colorMain} />}
          {car.interiorColor && <SpesItem label='Interiørfarge' value={car.interiorColor} marginBottom='0' />}
        </Grid>
      </Grid> */}
    </Box>
  );
};

const SpesItem = ({ label, value, marginBottom }) => {
  return (
    <>
      <Typography color='primary' style={{ fontSize: '16px', lineHeight: '22px', fontWeight: 300 }}>
        {label}
      </Typography>
      <Typography
        color='primary'
        style={{
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: 700,
          marginBottom: marginBottom ? marginBottom : '30px'
        }}
      >
        {value}
      </Typography>
    </>
  );
};

export const SpesificationsOld = ({ car, small }) => {
  const date = new Date(car.firstTimeRegistration);
  const firstTimeRegistration = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;

  return (
    <Box xs={12} style={{ padding: '20px 20px', backgroundColor: '#f5f4f4', marginTop: '30px' }}>
      <Typography color='primary' variant='h4' style={{ fontWeight: 'bold', margin: '0 0 40px' }}>
        Spesifikasjoner
      </Typography>
      {/* firstTimeRegistration */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {firstTimeRegistration && <SpesItem label='1. gang registrert' value={firstTimeRegistration} />}
          {car.regNum && <SpesItem label='Reg.nr' value={car.regNum} />}
          {car.vin && <SpesItem label='Chassisnr. (VIN)' value={car.vin} />}
          {car.registrationClass && <SpesItem label='Avgiftsklasse' value={car.registrationClass} />}
          {/* {<SpesItem label='Antall eiere' value={car.numOfOwners} marginBottom='0' />} */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {car.bodyType && <SpesItem label='Karosseri' value={car.bodyType} />}
          {car.numOfDoors && <SpesItem label='Antall dører' value={car.numOfDoors} />}
          {car.numOfSeats && <SpesItem label='Antall seter' value={car.numOfSeats} />}
          {car.wheelDrive && <SpesItem label='Hjuldrift' value={car.wheelDrive} marginBottom='0' />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {car.engineHp && <SpesItem label='Effekt' value={car.engineHp} />}
          {car.volume && (
            <SpesItem label='Sylindervolum' value={car.volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
          )}
          {car.weight && <SpesItem label='Vekt' value={car.weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />}
          {<SpesItem label='Str. lasterom' value={car.cargoVolume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />}

          {car.trailer && (
            <SpesItem
              label='Maksimal tilhengervekt'
              value={car.trailer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              marginBottom='0'
            />
          )}
          {car.vekter?.tillattTilhengervektMedBrems > 0 && (
            <SpesItem
              label='Maksimal tilhengervekt'
              value={car.vekter.tillattTilhengervektMedBrems.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              marginBottom='0'
            />
          )}
          {car.vekter?.tillattTaklast > 0 && (
            <SpesItem
              label='Maksimal taklast'
              value={car.vekter.tillattTaklast.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              marginBottom='0'
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ minHeight: '200px', borderLeft: '1px solid', paddingLeft: '25px' }}
        >
          {car.colorName && <SpesItem label='Farge' value={car.colorName} />}
          {car.colorMain && <SpesItem label='Fargebeskrivelse' value={car.colorMain} />}
          {car.interiorColor && <SpesItem label='Interiørfarge' value={car.interiorColor} marginBottom='0' />}
        </Grid>
      </Grid>
    </Box>
  );
};
