/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  IconButton,
  Popover
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const sizes = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3
};

export const Filters = ({ dealer, token }) => {
  const [filters, setFilters] = useState(dealer?.settings?.filters);
  const { enqueueSnackbar } = useSnackbar();
  // const updateFilters = useCallback(
  //   (filters) => {
  //     if (filters) {
  //       axios
  //         .put(
  //           process.env.REACT_APP_BACKEND_URL + '/admin/filters',
  //           { filters: filters, id: dealer.id },
  //           {
  //             headers: { Authorization: 'Bearer ' + token, 'x-access-token': token }
  //           }
  //         )
  //         .then((res) => {
  //           enqueueSnackbar('Endring lagret', { variant: 'success', preventDuplicate: true });
  //           // setMutate(Math.random());
  //         })
  //         .catch((reason) => {
  //           enqueueSnackbar('Kunne ikke lagre endringer', { variant: 'error', preventDuplicate: true });
  //         });
  //     }
  //   },
  //   [token, enqueueSnackbar, dealer.id]
  // );

  useEffect(() => {
    if (filters) {
      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + '/admin/filters',
          { filters: filters, id: dealer.id },
          {
            headers: { Authorization: 'Bearer ' + token, 'x-access-token': token }
          }
        )
        .then((res) => {
          enqueueSnackbar('Endring lagret', { variant: 'success', preventDuplicate: true });
          // setMutate(Math.random());
        })
        .catch((reason) => {
          enqueueSnackbar('Kunne ikke lagre endringer', { variant: 'error', preventDuplicate: true });
        });
    }
  }, [filters, enqueueSnackbar, dealer?.id, token]);

  // useAutosave({ data: filters, onSave: updateFilters, saveOnUnmount: false, });

  return (
    <Grid container spacing={3} alignItems='stretch'>
      <Make />
      {/* <Price price={filters?.price} setFilters={setFilters}  /> */}
      <Range
        field='price'
        values={filters?.price || { from: 0, to: 500000 }}
        thousandSeparator={' '}
        title='Pris'
        description='Filtrer på pris fra - til. Dersom fra/til er i min-/maks-possisjon vil lavere/høyere pris inkluderes i
            søkeresultatet.'
        setFilters={setFilters}
      />
      <Range
        field='year'
        values={
          filters?.year
            ? { from: filters.year.from, to: new Date().getFullYear() }
            : { from: 2010, to: new Date().getFullYear() }
        }
        title='Årsmodell'
        description='Filtrer på årmodell fra - til. Dersom fra/til er i min-/maks-possisjon vil lavere/høyere årsmodell inkluderes i
            søkeresultatet.'
        setFilters={setFilters}
      />
      <Range
        field='km'
        values={filters?.km || { from: 0, to: 200000 }}
        thousandSeparator={' '}
        title='Km.stand'
        description='Filtrer på km.stand fra - til. Dersom fra/til er i min-/maks-possisjon vil lavere/høyere km inkluderes i
            søkeresultatet.'
        setFilters={setFilters}
      />
    </Grid>
  );
};

const Range = ({ values, setFilters, title, description, field, thousandSeparator }) => {
  const [checked, setChecked] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? field : undefined;

  return (
    <Grid item sx={{ display: 'flex' }} {...sizes}>
      <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <CardContent>
          <Typography color='textSecondary' gutterBottom>
            Range slider
          </Typography>
          <Typography variant='h5' component='h2'>
            {title}
          </Typography>
          {/* <Typography className={classes.pos} color='textSecondary'>
            Dropdown
          </Typography> */}
          <Typography variant='body2' component='p'>
            {description}
          </Typography>

          <div>
            <IconButton
              aria-describedby={id}
              aria-label='info'
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <InfoOutlined />
            </IconButton>
            <Popover
              id={field}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <img style={{ width: '100px' }} src={'/images/admin/rangeinfo.jpg'} alt={''} />
            </Popover>
          </div>

          <form noValidate autoComplete='off'>
            <div>
              <NumberFormat
                margin='dense'
                size='small'
                customInput={TextField}
                thousandSeparator={thousandSeparator}
                required
                // id='price-min'
                value={values?.from}
                onValueChange={({ value }) => {
                  setFilters((filters) => ({
                    ...filters,
                    [field]: { from: parseInt(value) || 0, to: filters?.[field]?.to || 0 }
                  }));
                }}
                label='Min'
              />
            </div>
            <div>
              <NumberFormat
                margin='dense'
                size='small'
                customInput={TextField}
                thousandSeparator={thousandSeparator}
                required
                disabled={field === 'year'}
                // id='price-max'
                value={values?.to}
                onValueChange={({ value }) => {
                  setFilters((filters) => ({
                    ...filters,
                    [field]: { from: filters?.[field]?.from || 0, to: parseInt(value) }
                  }));
                }}
                label='Max'
              />
            </div>
          </form>
        </CardContent>
        <CardActions>
          <FormControlLabel
            disabled
            control={<Switch checked={checked} onChange={() => setChecked(!checked)} />}
            label='Bruk filter'
            labelPlacement='start'
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

// const Price = ({ sizes, price, setFilters }) => {
//   const classes = useStyles();
//   const [checked, setChecked] = useState(true);

//   return (
//     <Grid item className={classes.gridItem} {...sizes}>
//       <Card className={classes.card}>
//         <CardContent>
//           <Typography className={classes.title} color='textSecondary' gutterBottom>
//             Range slider
//           </Typography>
//           <Typography variant='h5' component='h2'>
//             Pris
//           </Typography>
//           {/* <Typography className={classes.pos} color='textSecondary'>
//             Dropdown
//           </Typography> */}
//           <Typography variant='body2' component='p'>
//             Filtrer på pris fra - til. Dersom fra/til er i min-/maks-possisjon vil lavere/høyere pris inkluderes i
//             søkeresultatet.
//           </Typography>
//           <form className={classes.root} noValidate autoComplete='off'>
//             <div>
//               <NumberFormat
//                 customInput={TextField}
//                 thousandSeparator={' '}
//                 required
//                 id='price-min'
//                 value={price?.from || 0}
//                 onValueChange={({ value }) =>
//                   setFilters((filters) => ({
//                     ...filters,
//                     price: { from: parseInt(value) || 0, to: filters?.price?.to || 0 }
//                   }))
//                 }
//                 label='Min'
//               />
//             </div>
//             <div>
//               <NumberFormat
//                 customInput={TextField}
//                 thousandSeparator={' '}
//                 required
//                 id='price-max'
//                 value={price?.to || 500000}
//                 onValueChange={({ value }) =>
//                   setFilters((filters) => ({
//                     ...filters,
//                     price: { from: filters?.price?.from || 0, to: parseInt(value) }
//                   }))
//                 }
//                 label='Max'
//               />
//             </div>
//           </form>
//         </CardContent>
//         <CardActions>
//           <FormControlLabel
//             disabled
//             control={<Switch checked={checked} onChange={() => setChecked(!checked)} />}
//             label='Bruk filter'
//             labelPlacement='start'
//           />
//         </CardActions>
//       </Card>
//     </Grid>
//   );
// };

const Make = () => {
  const [checked, setChecked] = useState(true);
  return (
    <Grid item style={{ display: 'flex' }} {...sizes}>
      <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <CardContent>
          <Typography color='textSecondary' gutterBottom>
            Dropdown
          </Typography>
          <Typography variant='h5' component='h2'>
            Merke
          </Typography>
          {/* <Typography className={classes.pos} color='textSecondary'>
            Dropdown
          </Typography> */}
          <Typography variant='body2' component='p'>
            Liste med merker genereres automatisk ut fra dine aktive objekter.
          </Typography>
        </CardContent>
        <CardActions>
          <FormControlLabel
            disabled
            control={<Switch checked={checked} onChange={() => setChecked(!checked)} />}
            label='Bruk filter'
            labelPlacement='start'
          />
        </CardActions>
      </Card>
    </Grid>
  );
};
