/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Typography, Slide, Avatar, Badge } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import CallMadeIcon from '@mui/icons-material/CallMade';
import useSWR from 'swr';
import { fetcher } from '../api/fetcher';

function createData({ car, id, modifiedDate, publishedDate, isActive, soldDate }) {
  const { make, model, regNum, images, isSold } = car;
  return { id, make, model, regNum, images, modifiedDate, publishedDate, isActive, isSold, soldDate };
}

const headCells = [
  {
    id: 'make',
    label: 'Merke'
  },
  {
    id: 'model',
    label: 'Modell'
  },
  {
    id: 'publishedDate',
    label: 'Lagt til'
  },
  {
    id: 'modifiedDate',
    label: 'Endret'
  }
];

export const Overview = ({ token, dealer, menuClick, firstName }) => {
  const {
    data: cars,
    isValidating,
    mutate
  } = useSWR(
    token ? [dealer ? process.env.REACT_APP_BACKEND_URL + `/admin/cars` : null, token, dealer?.id] : null,
    fetcher
  );

  // if (isValidating) return <LinearProgress />;
  return (
    <>
      <Container
        sx={{
          margin: '-25px',
          marginBottom: '50px',
          width: 'calc(100vw + 10px)',
          height: '100vh',
          maxWidth: 'unset',
          backgroundSize: 'cover',
          backgroundImage: "url('/images/admin/car.jpg')",
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <Slide direction='down' in={true} timeout={2000} mountOnEnter unmountOnExit>
          <Typography sx={{ fontSize: '30px', color: 'white', width: '100%', textAlign: 'center', paddingTop: '50px' }}>
            Velkommen tilbake{firstName ? ', ' + firstName : ''}!
          </Typography>
        </Slide>
      </Container>

      <ActiveCars dealerId={dealer?.id} cars={cars?.map((item) => createData(item)) || []} />
    </>
  );
};

const ActiveCars = ({ cars, dealerId }) => {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('publishedDate');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [activeCars, setActiveCars] = useState([]);

  useEffect(() => {
    setActiveCars(
      cars
        ? cars.filter((car) => {
            if (new Date(car.modifiedDate).getTime() > new Date().setDate(-500)) {
              if (car.isActive && !car.isSold) return true;
              if (car.soldDate) {
                const d = new Date();
                d.setDate(d.getDate() - 2);
                if (new Date(car.soldDate).getTime() > d.getTime()) return true;
                return false;
              }

              return false;
            }
            return false;
          })
        : []
    );
  }, [cars]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - activeCars.length) : 0;

  return (
    <Box sx={{ width: '99%' }}>
      <Paper elevation={2} sx={{ width: '100%', mb: 5 }}>
        <EnhancedTableToolbar numItems={activeCars.length} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {activeCars
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell padding='normal'>
                        <Badge
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          color='info'
                          badgeContent={row.isSold ? 'Solgt' : 0}
                        >
                          <Avatar
                            sx={{ width: 56, height: 56 }}
                            alt={row.make}
                            src={row.images.length ? row.images.sort((a, b) => a.priority - b.priority)[0].url : 'none'}
                          />
                        </Badge>
                      </TableCell>
                      <TableCell component='th' id={labelId}>
                        {row.make}
                      </TableCell>
                      <TableCell>{row.model}</TableCell>
                      <TableCell>{new Date(row.publishedDate).toLocaleString()}</TableCell>
                      <TableCell align='left'>{new Date(row.modifiedDate).toLocaleString()}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          aria-label='callmade'
                          color='primary'
                          onClick={() => window.open('/car/' + dealerId + '/' + row.id)}
                        >
                          <CallMadeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={
                    {
                      // height: (dense ? 33 : 53) * emptyRows,
                    }
                  }
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={activeCars.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='left' sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = ({ numItems }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
      }}
    >
      <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
        {numItems} aktive biler
      </Typography>

      <Tooltip title='Filter list'>
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

//  <Column
//     p={0}
//     gap={0}
//     sx={{
//       boxShadow: '0 8px 16px 0 #bdc9d7',
//       borderRadius: '16px',
//       marginBottom: '30px'
//     }}
//   >
//     <Row wrap p={2} alignItems={'baseline'}>
//       <Item stretched>
//         {cars
//           ? cars.filter((car) => {
//               if (new Date(car.modifiedDate).getTime() > new Date().setDate(-500)) {
//                 if (car.isActive && !car.car.isSold) return true;
//                 if (car.soldDate) {
//                   const d = new Date();
//                   d.setDate(d.getDate() - 2);
//                   if (new Date(car.soldDate).getTime() > d.getTime()) return true;
//                   return false;
//                 }

//                 return false;
//                 // $or: [
//                 //   { $and: [{ 'car.isSold': false }, { isActive: true }] },
//                 //   { soldDate: { $gt: new Date(soldDate.toISOString()) } }
//                 // ]
//               }
//               return false;
//             }).length
//           : 'Ingen'}{' '}
//         aktive biler
//       </Item>
//       <Item>
//         <Link onClick={() => mutate()}>Oppdater</Link> • <Link onClick={() => menuClick('cars')}>Se alle</Link>
//       </Item>
//     </Row>
//     {cars
//       ?.sort((a, b) => (a.publishedDate > b.publishedDate ? -1 : 1))
//       .slice(0, 3)
//       .map((car, index) => {
//         return (
//           <div key={car.id}>
//             <CarItem
//               name={`${car.car.make} ${car.car.model} `}
//               caption={'Lagt til  ' + new Date(car.publishedDate).toLocaleDateString()}
//               src={car.car.images.sort((a, b) => a.priority - b.priority)[0]?.url}
//               dealerId={dealer.id}
//               carId={car.id}
//             />
//             {index < 2 && <Divider variant={'middle'} />}
//           </div>
//         );
//       })}
//   </Column>

//   {cars?.filter((car) => car.soldDate).length > 0 && (
//     <Column
//       p={0}
//       gap={0}
//       sx={{
//         boxShadow: '0 8px 16px 0 #bdc9d7',
//         borderRadius: '16px',
//         marginBottom: '30px'
//       }}
//     >
//       <Row wrap p={2} alignItems={'baseline'}>
//         <Item stretched>Solgte biler</Item>
//       </Row>
//       {cars
//         ?.filter((car) => car.soldDate)
//         .sort((a, b) => (a.soldDate > b.soldDate ? -1 : 1))
//         .slice(0, 3)
//         .map((car, index) => {
//           return (
//             <div key={'sold' + index}>
//               <CarItem
//                 name={`${car.car.make} ${car.car.model} `}
//                 caption={'Solgt ' + new Date(car.soldDate).toLocaleDateString()}
//                 src={car.car.images.sort((a, b) => a.priority - b.priority)[0]?.url}
//                 dealerId={dealer.id}
//                 carId={car.id}
//               />
//               {index < 2 && <Divider variant={'middle'} />}
//             </div>
//           );
//         })}
//     </Column>
//   )}

// const CarItem = ({ src, name, caption, carId, dealerId }) => {
//   const avatarStyles = useDynamicAvatarStyles({ size: 56 });
//   return (
//     <Row gap={2} p={2.5}>
//       <Item>
//         <Avatar classes={avatarStyles} src={src}>
//           <img src={'/images/admin/car.svg'} alt='' />
//         </Avatar>
//       </Item>
//       <Row wrap grow gap={0.5} minWidth={0}>
//         <Item grow minWidth={0}>
//           <div
//             style={{
//               fontWeight: 600,
//               fontSize: '1rem',
//               color: '#122740',
//               maxWidth: '150px',
//               whiteSpace: 'nowrap',
//               textOverflow: 'ellipsis',
//               overflow: 'hidden'
//             }}
//           >
//             {name}
//           </div>
//           <div
//             style={{
//               whiteSpace: 'nowrap',
//               textOverflow: 'ellipsis',
//               overflow: 'hidden',
//               fontSize: '0.875rem',
//               color: '#758392',
//               marginTop: -4
//             }}
//           >
//             {caption}
//           </div>
//         </Item>
//         <Item position={'middle'}>
//           <Button
//             sx={{
//               borderRadius: 20,
//               padding: '0.125rem 0.75rem',
//               borderColor: '#becddc',
//               fontSize: '0.75rem'
//             }}
//             variant={'outlined'}
//             onClick={() => window.open('/car/' + dealerId + '/' + carId)}
//           >
//             Åpne
//           </Button>
//         </Item>
//       </Row>
//     </Row>
//   );
// };
