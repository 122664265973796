/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import { useAutosave } from 'react-autosave';
import useSWR from 'swr';
import axios from 'axios';
import { fetcher } from '../../api/fetcher';
import { getKjoretoy } from '../common';
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Grid,
  LinearProgress,
  TableSortLabel,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export const CarList = ({ token, dealer }) => {
  console.log(dealer);
  const theme = useTheme();
  const [vehicle, setVehicle] = useState();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  const {
    data: cars,
    mutate,
    isValidating
  } = useSWR(token ? [process.env.REACT_APP_BACKEND_URL + `/admin/cars`, token, dealer?.id] : null, fetcher);

  const newCar = () => {
    alert('This function must be replaced!!');
    setVehicle({
      // id: new ObjectID(),
      car: {
        regNum: 'el59521',
        vin: '',
        make: '',
        model: '',
        variant: '',
        registrationClass: '',
        bodyType: '',
        fuel: '',
        transmission: '',
        wheelDrive: '',
        firstTimeRegistration: '',
        colorMain: '',
        kontrollfrist: '',
        sistGodkjent: ''
      },
      contacts: {},
      location: {}
    });
  };
  const updateVehicle = useCallback(
    (vehicle) => {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/admin/vehicle', vehicle, {
          headers: { Authorization: 'Bearer ' + token, 'x-access-token': token }
        })
        .then((data) => {
          console.log(data);
          mutate();
        })
        .catch(console.error);

      // // filter the list, and return it with the updated item
      // const filteredTodos = todos.filter(todo => todo.id !== '1')
      // return [...filteredTodos, updatedTodo]
    },
    [mutate, token]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isValidating) return <LinearProgress />;

  return (
    <>
      <IconButton disabled onClick={newCar} aria-label='Add' style={{ backgroundColor: theme.palette.primary.light }}>
        <AddIcon />
      </IconButton>
      {vehicle && <EditCar vehicle={vehicle} setVehicle={setVehicle} token={token} updateVehicle={updateVehicle} />}

      {/* <div draggable style={{ width: '30px' }}>
        <EmojiEventsIcon />
      </div> */}
      <div style={{ width: '30px' }}>
        <EmojiEventsIcon />
      </div>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={cars?.length}
          />

          <TableBody>
            {cars &&
              stableSort(
                cars.map(({ car, isActive, id, publishedDate }) => ({
                  id: id,
                  publishedDate: publishedDate,
                  regNum: car.regNum,
                  // make: car.make,
                  model: car.make + ' ' + car.model,
                  isActive: isActive,
                  isSold: car.isSold
                })),
                getComparator(order, orderBy)
              )
                // .sort(
                //   (a, b) =>
                //     a.car.make.localeCompare(b.car.make) ||
                //     a.car.model.localeCompare(b.car.model) ||
                //     a.car.regNum.localeCompare(b.car.regNum)
                // )
                .map(({ id, regNum, make, model, isActive, isSold, publishedDate }) => (
                  <TableRow id={id} key={id}>
                    <TableCell align='left'>{new Date(publishedDate).toLocaleDateString()}</TableCell>
                    <TableCell align='left'>{regNum}</TableCell>
                    {/* <TableCell align='left'>{make}</TableCell> */}
                    <TableCell align='left'>{model}</TableCell>
                    <TableCell align='center'>{isActive ? 'Ja' : 'Nei'}</TableCell>
                    <TableCell align='center'>{isSold ? 'ja' : 'Nei'}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const EditCar = (params) => {
  const { vehicle, setVehicle, updateVehicle } = params;

  useAutosave({ data: vehicle, onSave: updateVehicle });

  const onChange = async ({ target }) => {
    let { id, value } = target;
    const newValues = { ...vehicle, car: { ...vehicle.car, [id]: value } };
    setVehicle(newValues);

    if (id === 'regNum') {
      value = value.charAt(0).toUpperCase() + value.charAt(1).toUpperCase() + value.slice(2);
      const newValues = { ...vehicle, car: { ...vehicle.car, [id]: value } };
      setVehicle(newValues);

      if (value.length === 7) {
        const kjoretoy = await getKjoretoy({ ...params, regNum: value });
        if (kjoretoy && kjoretoy.godkjenning) {
          newValues.car.vin = kjoretoy.kjoretoyId?.understellsnummer;
          newValues.car.firstTimeRegistration = kjoretoy.godkjenning.forstegangsGodkjenning?.forstegangRegistrertDato;
          const tekniskeData = kjoretoy?.godkjenning?.tekniskGodkjenning?.tekniskeData;
          newValues.car.registrationClass =
            kjoretoy?.godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.beskrivelse;
          if (tekniskeData) {
            const { dimensjoner, vekter, generelt, akslinger, karosseriOgLasteplan, miljodata, motorOgDrivverk } =
              tekniskeData;
            newValues.car.make = generelt.merke[0].merke;
            newValues.car.model = generelt.handelsbetegnelse[0];
            if (akslinger?.akselGruppe?.length === 2) {
              const frem = akslinger.akselGruppe.find(({ id }) => id === 1);
              const bak = akslinger.akselGruppe.find(({ id }) => id === 2);
              if (frem && bak) {
                newValues.car.wheelDrive = frem.akselListe?.aksel[0]?.drivAksel
                  ? bak.akselListe?.aksel[0]?.drivAksel
                    ? 'Firehjulsdrift'
                    : 'Forhjulsdrift'
                  : 'Bakhjulsdrift';
              }
            }
            if (karosseriOgLasteplan?.rFarge?.length) {
              newValues.car.farge = karosseriOgLasteplan.rFarge[0].kodeNavn;
            }
            if (miljodata?.miljoOgdrivstoffGruppe?.length) {
              newValues.car.fuel = miljodata.miljoOgdrivstoffGruppe[0].drivstoffKodeMiljodata?.kodeNavn;
            } else if (motorOgDrivverk?.motor?.length === 1) {
              newValues.car.fuel = motorOgDrivverk.motor[0].drivstoff[0].drivstoffKode.kodeNavn;
            }
            if (motorOgDrivverk?.girkassetype) {
              newValues.car.transmission = motorOgDrivverk.girkassetype.kodeNavn;
            }
          }
          if (kjoretoy?.periodiskKjoretoyKontroll?.kontrollfrist) {
            newValues.car.kontrollfrist = new Date(kjoretoy.periodiskKjoretoyKontroll.kontrollfrist);
          }
          if (kjoretoy?.periodiskKjoretoyKontroll?.sistGodkjent) {
            newValues.car.sistGodkjent = new Date(kjoretoy.periodiskKjoretoyKontroll.sistGodkjent);
          }
          setVehicle({
            ...newValues
          });
        }
        // console.log(kjoretoy);
      }
    }
  };

  return (
    <Grid>
      {Object.keys(vehicle.car).map((key, index) => {
        return (
          <TextField
            key={`car-${index}`}
            id={key}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            value={vehicle.car[key]}
            onChange={onChange}
          />
        );
      })}
    </Grid>
  );
};

function descendingComparator(a, b, orderBy) {
  if (a[orderBy] && b[orderBy] && typeof a[orderBy] === 'string') {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) return -1;
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) return 1;
  }

  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'publishedDate', label: 'Publisert' },
  { id: 'regNum', label: 'Regnr' },
  // { id: 'make', label: 'Merke' },
  { id: 'model', label: 'Model' },
  { id: 'isActive', label: 'Aktiv' },
  { id: 'isSold', label: 'Solgt' }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// function isAlphaNumeric(str) {
//   var code, i, len;

//   for (i = 0, len = str.length; i < len; i++) {
//     code = str.charCodeAt(i);
//     if (
//       !(code > 47 && code < 58) && // numeric (0-9)
//       !(code > 64 && code < 91) && // upper alpha (A-Z)
//       !(code > 96 && code < 123)
//     ) {
//       // lower alpha (a-z)
//       return false;
//     }
//   }
//   return true;
// }
