/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

export const Sellers = ({ token }) => {
  useEffect(() => {
    if (token) {
      fetch(process.env.REACT_APP_BACKEND_URL + '/admin/sellers', {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
          'x-access-token': token,
          Authorization: 'Bearer ' + token
        }
      }).then(async (data) => {
        console.log(await data.json());
      });
    }
  }, [token]);

  return <div>Sellers</div>;
};
