/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  List,
  Avatar,
  ListItemAvatar,
  ListItemSecondaryAction,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  MenuItem,
  Chip,
  FormControl,
  Badge,
  Tooltip,
  ListItemButton
} from '@mui/material';
import { Delete, Add, LocalPolice, Person } from '@mui/icons-material';
import validator from 'validator';
import useSWR from 'swr';
import { fetcher } from '../api/fetcher';
import { useSnackbar } from 'notistack';
import { teal } from '@mui/material/colors';

export const Users = ({ token, dealer, dealers }) => {
  const theme = useTheme();
  const [user, setUser] = useState();
  const [addUserOpen, setAddUserOpen] = useState(false);

  const { data: users, isValidating: loadingLocations } = useSWR(
    token ? [process.env.REACT_APP_BACKEND_URL + `/admin/users`, token, dealer?.id] : null,
    fetcher
  );

  return (
    <Grid item xs={12} md={6}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <IconButton onClick={() => setAddUserOpen(true)} sx={{ backgroundColor: theme.palette.primary.light }}>
          <Add />
        </IconButton>
        <AddUserDialog
          addUserOpen={addUserOpen}
          setAddUserOpen={setAddUserOpen}
          user={user}
          setUser={setUser}
          token={token}
          dealers={dealers}
          dealerId={dealer?.id}
        />
      </div>

      <div>
        <List>
          {users?.map((user) => (
            <ListItem key={user._id}>
              <ListItemButton>
                <ListItemAvatar>
                  <Tooltip title={user.superUser ? 'Systemadministrator' : ''} arrow placement='top'>
                    <Badge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      invisible={!user.superUser}
                      badgeContent={<LocalPolice color='warning' />}
                    >
                      <Avatar sx={{ bgcolor: user.active ? teal[300] : '#ccc' }}>
                        <Person />
                      </Avatar>
                    </Badge>
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={user.first_name ? `${user.first_name} ${user.last_name ? user.last_name : ''}` : user.email}
                  sx={{ color: user.active ? 'black' : '#999' }}
                />
                <ListItemSecondaryAction>
                  <IconButton disabled edge='end' aria-label='delete'>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </Grid>
  );
};

const AddUserDialog = ({ addUserOpen, setAddUserOpen, token, setUser, user, dealers, dealerId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('');
  const [validUser, setValidUser] = useState(false);
  const [dealerIds, setDealerIds] = React.useState([dealerId]);
  const handleDealersChange = (event) => {
    const {
      target: { value }
    } = event;
    setDealerIds(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    setDealerIds(dealerId ? [dealerId] : []);
  }, [dealerId]);

  useEffect(() => {
    if (token && username.length > 4 && dealerIds.length) {
      if (validator.isEmail(username)) {
        setValidUser(true);
      } else setValidUser(false);
    } else {
      setValidUser(false);
    }
  }, [username, token, dealerIds]);

  const createUser = () => {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + '/admin/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ email: username, dealers: dealerIds })
      })
        .then(async (res) => {
          console.log('OK', res);

          resolve(res);
        })
        .catch((err) => {
          console.log('ERR', err);
          reject(err);
        });
    });
  };

  return (
    <Dialog
      open={addUserOpen}
      onClose={() => {
        setAddUserOpen(false);
      }}
    >
      <DialogTitle id='form-dialog-title'>Legg til bruker</DialogTitle>
      <DialogContent>
        <DialogContentText>Brukeren vil motta en e-post med innloggingsinformasjon.</DialogContentText>
        {/* <DialogContentText>Velg en eller flere kontoer brukeren skal ha tilgang til.</DialogContentText> */}
        <FormControl fullWidth sx={{ margin: '20px 0' }}>
          <div style={{ marginBottom: '20px' }}>
            <InputLabel id='account-label'>Forhandler(e)</InputLabel>
            <Select
              fullWidth
              autoFocus
              labelId='account-label'
              label='Forhandler(e)'
              multiple
              value={dealerIds}
              onChange={handleDealersChange}
              input={<OutlinedInput id='select-multiple-chip' label='Forhandler(e)' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((dealerId) => (
                    <Chip key={dealerId} label={dealers.find((dealer) => dealer.id === dealerId)?.name} />
                  ))}
                </Box>
              )}
            >
              {dealers?.map((dealer) => (
                <MenuItem key={dealer.id} value={dealer.id}>
                  {dealer.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <TextField
              fullWidth
              // margin='dense'
              error={username.length > 4 && !validator.isEmail(username)}
              // helperText={username.length > 4 && validUser !== true ? validUser : validUser}
              id='name'
              label='Brukernavn (e-post)'
              type='text'
              value={username}
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
            />
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddUserOpen(false)} color='secondary' variant='outlined'>
          Avbryt
        </Button>
        <Button
          disabled={validUser !== true}
          onClick={() => {
            setValidUser(false);
            createUser()
              .then((res) => {
                if (res.ok) {
                  enqueueSnackbar('Bruker opprettet');
                  setAddUserOpen(false);
                } else {
                  enqueueSnackbar('Bruker ble ikke opprettet', { variant: 'error', preventDuplicate: true });
                }
                console.log(res.ok);
              })
              .catch((res) => {
                enqueueSnackbar('Bruker ble ikke opprettet', { variant: 'error', preventDuplicate: true });
              });
          }}
          color='primary'
          variant='outlined'
        >
          Legg til
        </Button>
      </DialogActions>
    </Dialog>
  );
};
