import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fuse from 'fuse.js';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  Badge,
  Typography,
  Box,
  AppBar,
  Toolbar,
  LinearProgress,
  Slide,
  useScrollTrigger,
  Button
} from '@mui/material';
import { Filters } from './Filters';
import { Car } from '../cars/Car';
import ReactGA from 'react-ga';

const isDark = (hex) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Hvis luminance er over 0.35, er fargen "mørk".
  return luminance > 0.35 ? true : false;
};

const StyledCarCard = styled('div')(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: '20px',
    width: '50px',
    color: isDark(theme.palette.secondary.light) ? 'white' : theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light
  },
  '& .priceBox': {
    padding: '10px 25px',
    backgroundColor: theme.palette.secondary.main,
    color: isDark(theme.palette.secondary.light) ? 'white' : theme.palette.primary.main
  }
}));
const StyledLogoImg = styled('img')(() => ({
  height: '20px',
  padding: '5px',
  cursor: 'pointer'
}));
const StyledCarBox = styled('div')(() => ({
  margin: '20px 0 0 0',
  textTransform: 'uppercase',
  flexWrap: 'wrap'
}));
const StyledCarBox2 = styled('div')(() => ({
  margin: '20px 0'
}));
// const StyledAppBar = styled(AppBar)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main
// }));
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& ': {
    backgroundColor: theme.palette.primary.main
  }
}));

const equipmentMapping = [
  {
    label: 'Hengerfeste',
    values: [
      'Hengerfeste',
      'Avtagbart tilhengerfeste',
      'Avtagbart hengerfeste',
      'Tilhengerfeste elektrisk innfellbart',
      'Tilhengerfeste',
      'Hengerfeste - kontakt selger for tilbud',
      'Hengerfeste svingbart',
      'Elektrisk innfellbart hengerfeste',
      'Utsvingbart tilhengerfeste',
      'Hengerfeste, avtagbart/svingbart'
    ]
  },
  {
    label: 'Motorvarmer',
    values: [
      'Motorvarmer',
      'Webasto fjernstyrt parkeringsvarmer',
      'Webasto med fjernkontroll',
      'Webasto m/fjernkontroll',
      'Diesel varmer med fjernkontroll',
      'Dieseldrevet motor- og kupevarmer med fjernkontroll',
      'Fjernkontroll for dieselvarmer',
      'Programbar Varmer',
      'Kupevarmer, fjernbetjent'
    ]
  },
  {
    label: 'Navigasjonsutstyr',
    values: ['Navigasjon', 'Navigasjonssystem', 'Navigasjon Sync 3 med 8" berøringsskjerm', 'Navigasjonspakke']
  },
  {
    label: 'Skinninteriør',
    values: [
      'Skinnseter med el. justering',
      'Skinnseter med El.justeringer og Memory',
      'Skinninteriør',
      'Delskinn',
      'Skinnseter',
      'Skinn',
      'Recaro skinnseter med justerbar sittepute',
      'Semsket skinn',
      'Skinn/alcantara sportspakke plus',
      'Seter i delskinn'
    ]
  }
];

const LocationsList2 = ({ match, setTheme }) => {
  const { dealerId, locationId, carId, link } = match.params;
  const [priceRangeSetting, setPriceRangeSetting] = useState({
    from: 0,
    to: 500000
  });
  const [yearRangeSetting, setYearRangeSetting] = useState({
    from: 2010,
    to: new Date().getFullYear()
  });
  const [kmRangeSetting, setKmRangeSetting] = useState({
    from: 0,
    to: 200000
  });

  const [dealer, setDealer] = useState({});
  const [locations, setLocations] = useState([]);
  const [places, setPlaces] = useState([]);
  const [placeNames, setPlaceNames] = useState([]);
  const [loadCars, setLoadCars] = useState(true);
  const [cars, setCars] = useState();
  const [filteredCars, setFilteredCars] = useState();
  const [sorting, setSorting] = useState('modifiedDate');
  const [selectedLocations, setSelectedLocations] = useState(['all']);
  const [selectedPlaces, setSelectedPlaces] = useState(['all']);
  const [makeList, setMakeList] = useState([]);
  const [selectedMakes, setSelectedMakes] = useState(['all']);
  const [registrationClass, setRegistrationClass] = useState([]);
  const [selectedRegistrationClass, setSelectedRegistrationClass] = useState(['all']);
  const [fuels, setFuels] = useState([]);
  const [selectedFuels, setSelectedFuels] = useState(['all']);
  const [equipments] = useState(['Hengerfeste', 'Motorvarmer', 'Navigasjon', 'Skinnseter']);
  const [selectedEquipments, setSelectedEquipments] = useState(['all']);
  const [transmissions, setTransmissions] = useState([]);
  const [selectedTransmissions, setSelectedTransmissions] = useState(['all']);
  const [wheelDrives, setWheelDrives] = useState([]);
  const [selectedWheelDrives, setSelectedWheelDrives] = useState(['all']);
  const [priceRange, setPriceRange] = useState({ from: 0, to: 500000 });
  const [yearRange, setYearRange] = useState({ from: 2010, to: new Date().getFullYear() });
  const [kmRange, setKmRange] = useState({ from: 0, to: 200000 });
  const [dealerName, setDealerName] = useState();
  const [textSearch, setTextSearch] = useState('');
  const [messageInterval, setMessageInterval] = useState(false);
  const [car, setCar] = useState();
  const [filterByCarIdString, setFilterByCarIdString] = useState(false);

  useEffect(() => {
    if (dealer?.locations && carId && dealerId) {
      const location = dealer.locations.find((location) => location.id === carId);
      const place = dealer.places.find((place) => place.name === decodeURIComponent(carId));

      const regexDigit = /^\d/;
      const regexUuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      const startsWithDigit = regexDigit.test(carId);
      const isUuid = regexUuid.test(carId);
      const doesNotStartWithDigitAndIsNotUuid = !(startsWithDigit || isUuid);

      // if (!/^\d/.test(carId)) {
      if (doesNotStartWithDigitAndIsNotUuid) {
        // if carId is "kuga", "ioniq-5", etc
        setTextSearch(carId);
        setFilterByCarIdString(true);
      } else if (place) {
        setSelectedPlaces([place.name]);
        const selectedLocations = [place]
          .map((selectedPlace) => selectedPlace.locations.map((location) => location.name))
          .flat();
        setSelectedLocations(selectedLocations);
      } else if (location) {
        setSelectedLocations([location.name]);
      } else {
        setCar({ carId: carId, dealerId: dealerId });
      }
    }
  }, [carId, dealerId, dealer]);

  useEffect(() => {
    if (dealer?.settings?.filters?.price) {
      setPriceRange(dealer.settings.filters.price);
      setPriceRangeSetting(dealer.settings.filters.price);
    }
    if (dealer?.settings?.filters?.year?.from) {
      setYearRange({ from: dealer.settings.filters.year.from, to: new Date().getFullYear() });
      setYearRangeSetting({ from: dealer.settings.filters.year.from, to: new Date().getFullYear() });
    }
    if (dealer?.settings?.filters?.km) {
      setKmRange(dealer.settings.filters.km);
      setKmRangeSetting(dealer.settings.filters.km);
    }
  }, [dealer]);

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.postMessage({ params: car ? car : 'reset' }, '*');
    }
  }, [car]);

  // useEffect(() => {
  //   const handler = (event) => {
  //     // const data = JSON.parse(event.data);
  //     console.log('Hello World?', event.data);
  //   };

  //   window.addEventListener('message', handler);

  //   return () => window.removeEventListener('message', handler);
  // }, []);

  useEffect(() => {
    if (window.self !== window.top && !messageInterval) {
      setMessageInterval(true);
      setInterval(() => {
        const message = {
          height: document.body.scrollHeight,
          width: document.body.scrollWidth - 50
          // params: { dealerId: dealerId, locationId: locationId, ...car }
        };
        window.top.postMessage(message, '*');
      }, 500);
    }
  }, [setMessageInterval, messageInterval]);

  const resetFilters = () => {
    setSelectedPlaces(['all']);
    setSelectedLocations(['all']);
    setSelectedMakes(['all']);
    setSelectedRegistrationClass(['all']);
    setSelectedFuels(['all']);
    setSelectedEquipments(['all']);
    setSelectedTransmissions(['all']);
    setSelectedWheelDrives(['all']);
    setPriceRange({ from: 0, to: 500000 });
    setYearRange({ from: 2010, to: new Date().getFullYear() });
    setKmRange({ from: 0, to: 200000 });
    setTextSearch('');
    if (dealer?.id === '610285855c8cf3574d4c90f6' || dealer?.id === '65c206c21fe9df14d534fa97') {
      fetch(process.env.REACT_APP_BACKEND_URL + '/cars/' + dealerId).then((res) => {
        res.json().then((cars) => {
          setCars(cars);
        });
      });
    }
  };

  useEffect(() => {
    if (loadCars) {
      setLoadCars(false);

      fetch(process.env.REACT_APP_BACKEND_URL + '/cars/' + dealerId).then((res) => {
        res.json().then((cars) => {
          const regexUuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
          const isUuid = (cars.length > 0 && regexUuid.test(cars[0].id)) || false;

          setMakeList([...new Set(cars.map((car) => car.car.make))]);

          if (isUuid) {
            setRegistrationClass(['Personbil', 'Varebil']);
            setWheelDrives(['Bakhjulsdrift', 'Forhjulsdrift', 'Firehjulsdrift']);
          } else {
            setRegistrationClass([...new Set(cars.map((car) => car.car.registrationClass))]);
            setWheelDrives([...new Set(cars.map((car) => car.car.wheelDrive))]);
          }
          setFuels([
            ...new Set(
              cars.map((car) => car?.car?.fuel?.indexOf('hjul') < 0 && car?.car?.fuel?.replace('Strøm', 'Elektrisk'))
            )
          ]);
          setTransmissions([...new Set(cars.map((car) => car.car.transmission))]);
          setCars(cars);

          // setEquipments([...new Set(cars.map((car) => car.car.equipments.map((equipment) => equipment.text)).flat(2))]);
          // console.log([...new Set(cars.map((car) => car.car.equipments.map((equipment) => equipment.text)).flat(2))]);
          // cars.map((car) => {
          //   console.log(car.id);
          //   if (car.id === '610286345c8cf3574d4d0cac') {
          //     console.log(car);
          //   }
          // });
        });
      });
      fetch(process.env.REACT_APP_BACKEND_URL + '/dealer/' + dealerId).then((res) => {
        res.json().then((dealer) => {
          setDealer(dealer);
          if (dealer.theme) {
            setTheme({
              palette: {
                primary: {
                  main: dealer.theme.primary?.main ? dealer.theme.primary.main : null,
                  light: dealer.theme.primary?.light ? dealer.theme.primary.light : null
                },
                secondary: {
                  main: dealer.theme.secondary?.main ? dealer.theme.secondary.main : null,
                  light: dealer.theme.secondary?.light ? dealer.theme.secondary.light : null
                }
              }
            });
          }
          setDealerName(dealer.name);
          setLocations([...new Set(dealer.locations.map((location) => location.name))]);
          setPlaces([...new Set(dealer.places.map((place) => place))]);
          setPlaceNames([...new Set(dealer.places.map((place) => place.name))]);
          if (locationId) {
            const location = dealer.locations.find((location) => location.id === locationId);
            if (location) {
              setSelectedLocations([location.name]);
            }
          }
        });
      });
    }
  }, [dealerId, loadCars, setTheme, locationId]);

  useEffect(() => {
    if (cars && cars.length) {
      const regexUuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      const isUuid = (cars.length > 0 && regexUuid.test(cars[0].id)) || false;

      const filtered = cars.filter(
        (car) =>
          ((isUuid && true) || carfilter(car.car.registrationClass, selectedRegistrationClass)) &&
          ((isUuid && true) || carfilter(car.car.wheelDrive, selectedWheelDrives)) &&
          ((isUuid && true) || carfilter(car.car.equipments, selectedEquipments, equipmentMapping)) &&
          carfilter(car.car.make, selectedMakes) &&
          car.car.fuel &&
          carfilter(car.car.fuel.replace('Strøm', 'Elektrisk'), selectedFuels) &&
          carfilter(car.car.transmission, selectedTransmissions) &&
          carfilter(car.location.name, selectedLocations) &&
          rangeFilter(car.car.price, priceRange, priceRangeSetting) &&
          rangeFilter(car.car.yearModel, yearRange, yearRangeSetting) &&
          rangeFilter(car.car.mileage, kmRange, kmRangeSetting)
      );
      const sortFunction = (a, b) => {
        if (sort === 'modifiedDate') {
          return a.publishedDate > b.publishedDate ? -1 : 1;
        }
        return a.car[sort] > b.car[sort] ? direction : -1 * direction;
      };
      const direction = sorting.indexOf('-') >= 0 ? -1 : 1;
      const sort = sorting.replace('-', '');

      const fuse = new Fuse(filtered, {
        threshold: 0.2,
        ignoreLocation: true,
        keys: [
          'car.model',
          'car.make',
          'car.regNum',
          'car.variant',
          'car.bodyType',
          'car.yearModel',
          'car.fuel',
          'car.transmission'
        ]
      });
      const searched = textSearch ? fuse.search(textSearch).map((res) => res.item) : filtered;
      searched.sort(sortFunction);

      setFilteredCars(searched);

      ReactGA.event({
        category: 'Filter',
        action: 'List',
        label: JSON.stringify([searched.length, cars.length])
      });
    }
  }, [
    cars,
    selectedMakes,
    selectedRegistrationClass,
    selectedFuels,
    priceRange,
    yearRange,
    kmRange,
    selectedTransmissions,
    selectedEquipments,
    selectedWheelDrives,
    selectedLocations,
    sorting,
    textSearch,
    kmRangeSetting,
    priceRangeSetting,
    yearRangeSetting,
    dealer.name
  ]);

  const registrationClassChanged =
    dealer?.id === '610285855c8cf3574d4c90f6' || dealer?.id === '65c206c21fe9df14d534fa97'
      ? (selected) => {
          let varebil = 'all';
          let drivingWheel = selectedWheelDrives.indexOf('all') < 0 ? selectedWheelDrives.join() : 'all';
          let equipment = selectedEquipments.indexOf('all') < 0 ? selectedEquipments : 'all';

          if (selected.length === 1 && selected.indexOf('Varebil') > -1) varebil = 'Varebil';
          else if (selected.length === 1 && selected.indexOf('Personbil') > -1) varebil = 'Personbil';

          // console.log('varebil', varebil);

          fetch(
            process.env.REACT_APP_BACKEND_URL +
              '/cars/' +
              dealerId +
              '/' +
              varebil +
              '/' +
              drivingWheel +
              '/' +
              equipment
            // btoa('properties.segment=Nyttekj%C3%B8ret%C3%B8y&properties.segment=Varebil')
          ).then((res) => {
            res.json().then((cars) => {
              setCars(cars);
            });
          });
        }
      : null;

  const drivingWheelChanged =
    dealer?.id === '610285855c8cf3574d4c90f6' || dealer?.id === '65c206c21fe9df14d534fa97'
      ? (selected) => {
          let drivingWheel = selected.indexOf('all') < 0 ? selected.join() : 'all';
          let equipment = selectedEquipments.indexOf('all') < 0 ? selectedEquipments : 'all';
          let varebil = 'all';
          if (selectedRegistrationClass.length === 1 && selectedRegistrationClass.indexOf('Varebil') > -1)
            varebil = 'Varebil';
          else if (selectedRegistrationClass.length === 1 && selectedRegistrationClass.indexOf('Personbil') > -1)
            varebil = 'Personbil';

          fetch(
            process.env.REACT_APP_BACKEND_URL +
              '/cars/' +
              dealerId +
              '/' +
              varebil +
              '/' +
              drivingWheel +
              '/' +
              equipment
            // btoa('properties.segment=Nyttekj%C3%B8ret%C3%B8y&properties.segment=Varebil')
          ).then((res) => {
            res.json().then((cars) => {
              setCars(cars);
            });
          });
        }
      : null;

  const equipmentChanged =
    dealer?.id === '610285855c8cf3574d4c90f6' || dealer?.id === '65c206c21fe9df14d534fa97'
      ? (selected) => {
          let equipment = selected.indexOf('all') < 0 ? selected : 'all';

          let drivingWheel = selectedWheelDrives.indexOf('all') < 0 ? selectedWheelDrives.join() : 'all';
          let varebil = 'all';
          if (selectedRegistrationClass.length === 1 && selectedRegistrationClass.indexOf('Varebil') > -1)
            varebil = 'Varebil';
          else if (selectedRegistrationClass.length === 1 && selectedRegistrationClass.indexOf('Personbil') > -1)
            varebil = 'Personbil';

          fetch(
            process.env.REACT_APP_BACKEND_URL +
              '/cars/' +
              dealerId +
              '/' +
              varebil +
              '/' +
              drivingWheel +
              '/' +
              equipment
            // btoa('properties.segment=Nyttekj%C3%B8ret%C3%B8y&properties.segment=Varebil')
          ).then((res) => {
            res.json().then((cars) => {
              setCars(cars);
            });
          });
        }
      : null;

  const placesChange = (selectedPlaceNames) => {
    ReactGA.event({
      category: 'Filter',
      action: 'Places',
      label: JSON.stringify(selectedPlaceNames)
    });

    if (selectedPlaceNames[0] === 'all') {
      setSelectedLocations(['all']);
    } else {
      const selectedPlaces = places.filter((place) => selectedPlaceNames.indexOf(place.name) >= 0);
      const selectedLocations = selectedPlaces
        .map((selectedPlace) => selectedPlace.locations.map((location) => location.name))
        .flat();
      setSelectedLocations(selectedLocations);
    }
  };
  const carfilter = (value, selectedList, maping) => {
    if (selectedList.find((selected) => selected === 'all')) return true;
    else if (typeof value === 'object' && value.length >= 1) {
      if (maping) {
        const results = maping
          .filter((map) => selectedList.indexOf(map.label) >= 0)
          .map(({ values }) => {
            return value.some((element) => {
              return !!values.find((value) => value === element.text);
            });
          });
        return results.indexOf(false) < 0; //All must be fullfilled (AND for this kind of filter. Ex: "Webasto" AND "Navigation" must be true)
      }
      return value.some((element) => {
        return !!selectedList.find((selected) => selected === element.text);
      });
    } else if (selectedList.find((selected) => selected === value)) return true;
    return false;
  };
  const rangeFilter = (value, range, rangeSetting) => {
    if (range.from === rangeSetting.from && range.to === rangeSetting.to) return true;
    if (range.from === rangeSetting.from && value <= range.to) return true;
    if (range.to === rangeSetting.to && value >= range.from) return true;
    if (value >= range.from && value <= range.to) return true;
    return false;
  };

  const props = {
    places,
    placeNames,
    selectedPlaces,
    setSelectedPlaces,
    placesChange,
    locations,
    selectedLocations,
    setSelectedLocations,
    makeList,
    selectedMakes,
    setSelectedMakes,
    registrationClass,
    selectedRegistrationClass,
    setSelectedRegistrationClass,
    fuels,
    selectedFuels,
    setSelectedFuels,
    wheelDrives,
    selectedWheelDrives,
    setSelectedWheelDrives,
    transmissions,
    setSelectedTransmissions,
    selectedTransmissions,
    equipments,
    selectedEquipments,
    setSelectedEquipments,
    priceRange,
    setPriceRange,
    yearRange,
    setYearRange,
    kmRange,
    setKmRange,
    sorting,
    setSorting,
    filteredCars,
    resetFilters,
    priceRangeSetting,
    kmRangeSetting,
    yearRangeSetting,
    dealerName,
    textSearch,
    setTextSearch,
    registrationClassChanged,
    drivingWheelChanged,
    equipmentChanged
  };

  // export const Car = ({ match, setTheme }) => {
  //   const { carId, dealerId } = match.params;
  // window.parentIFrame.scrollTo(0, 0);

  return (
    <>
      {dealer?.settings?.logo && <Logo logo={dealer.settings.logo} car={car ? true : false} />}
      {car ? (
        <Car
          match={{ params: { carId: car.carId, dealerId: car.dealerId, link: link } }}
          setTheme={setTheme}
          resetCar={setCar}
          topBar={dealer?.settings?.logo ? true : false}
        />
      ) : (
        <>
          {filteredCars ? (
            <>
              {!filterByCarIdString && <Filters {...props} />}
              <Cars cars={filteredCars} logo={dealer && dealer.logo} dealerId={dealer && dealer.id} setCar={setCar} />
            </>
          ) : (
            <LinearProgress />
          )}
        </>
      )}
    </>
  );
};

export const LocationsList = React.memo(LocationsList2);

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

const Logo = (props) => {
  const { logo, car } = props;
  const theme = useTheme();
  return (
    <>
      <div style={{ height: '64px' }}></div>
      <HideOnScroll {...props}>
        <StyledAppBar>
          <Toolbar>
            <StyledLogoImg
              src={'/images' + logo?.img}
              alt=''
              onClick={(e) => {
                e.preventDefault();
                window.location.href = logo?.url;
              }}
            />
            {/* {car && window.history.length > 1 && (
              <Button
                // variant='text'
                size='small'
                // color={'red'}

                sx={{
                  color: theme.palette.secondary.main
                }}
                onClick={() => {
                  window.history.back();
                }}
                startIcon={<ArrowBackIcon />}
              >
                Til søket
              </Button>
            )} */}
          </Toolbar>
        </StyledAppBar>
      </HideOnScroll>
    </>
  );
};

const Cars = ({ cars, logo, dealerId, setCar }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const [minHeight, setMinHeight] = useState('17vw');

  useEffect(() => {
    if (xl) {
      setMinHeight('15vw');
    } else if (lg) {
      setMinHeight('17vw');
    } else if (md) {
      setMinHeight('22vw');
    } else if (sm) {
      setMinHeight('34vw');
    } else if (xs) {
      setMinHeight('16vw');
    }
  }, [xs, sm, md, lg, xl]); // Only re-run the effect if isMedium changes

  return (
    <Grid container spacing={3} sx={{ width: 'calc(100% + 6px)', margin: 'unset', marginLeft: '-12px' }}>
      {cars &&
        cars.map((car) => (
          <CarCard
            setCar={setCar}
            key={car.id}
            id={car.id}
            car={car.car}
            modifiedDate={car.modifiedDate}
            publishedDate={car.publishedDate}
            postLocation={car.location.postLocation}
            logo={logo}
            dealerId={dealerId}
            minHeight={minHeight}
          />
        ))}
    </Grid>
  );
};

const CarImage = ({ images, alt, logo, minHeight }) => {
  const [imageNr, setImageNr] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [image, setImage] = useState('/images/car_placeholder2.jpeg');

  useEffect(() => {
    if (images && images.length > imageNr) {
      setImage(images[imageNr].url);
    }
  }, [images, imageNr]);

  let onError = () => {
    if (imageNr < images.length - 1 && imageNr <= 0) {
      setImageNr(imageNr + 1);
    } else {
      onError = null;
      setImage('/images/car_placeholder.jpeg');
    }
  };

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      <CardMedia
        component='img'
        alt={alt}
        height='auto'
        image={image}
        title={alt}
        onError={onError}
        onLoad={onLoad}
        style={{
          objectFit: minHeight !== '16vw' ? 'contain' : 'cover',
          minHeight: minHeight,
          display: loaded ? 'block' : 'none'
          // aspectRatio: '16/9'
        }}
      />
      {!loaded && <LinearProgress />}
    </>
  );
};

const CarCard = ({ car, id, postLocation, logo, dealerId, minHeight, setCar, publishedDate }) => {
  // let history = useHistory();

  // const theme = useTheme();

  function handleClick(id) {
    // window.parentIFrame &&
    //   window.parentIFrame.getPageInfo((pageInfo) => {
    //     console.log(pageInfo);
    //   });

    if (dealerId === '6167371d78ffe648b3d228f8') {
      window.open('https://hoylandet-auto.no/bruktbiler/?car=' + id, '_blank');
      // #carId=12345&dealerId=6789
    } else if (dealerId === '610285855c8cf3574d4c90f6') {
      window.open('https://auto8-8.no/bruktbil/#carId=' + id + '&dealerId=' + dealerId, '_blank');
    } else {
      window.open('/car/' + dealerId + '/' + id, '_blank');
    }
    // setCar({ carId: id, dealerId: dealerId });
    // window.history.pushState({}, null, '/car/' + dealerId + '/' + id);

    // console.log(document.body.scrollTop, window.pageYOffset);
    // console.log(window.top.scrollY);
    // // console.log(document);
    // console.log(window);
    // // window.scrollTo({ top: 100 });
    // window.self.scrollTo(100, 100);
  }

  //car.images.sort((a, b) => (a.priority > b.priority ? 1 : -1));
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <StyledCarCard>
        <Badge
          overlap='rectangular'
          invisible={car.isSold ? false : true}
          badgeContent={'Solgt'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Card sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
            {/* <CardActionArea component={RouterLink} to='/questions'> */}
            {/* <Link to={`/car/${car.id}`}> */}
            {/* <CardActionArea component={Link} to={'/car/' + dealerId + '/' + id} target='_blank'> */}
            <CardActionArea onClick={() => handleClick(id)}>
              {/* <CardActionArea component={Link} to={'/car/' + dealerId + '/' + id}> */}
              {/* <CardActionArea> */}
              <CarImage
                images={car.images.sort((a, b) => (a.priority > b.priority ? 1 : -1))}
                alt={car.make}
                logo={logo}
                minHeight={minHeight}
              />
              <CardContent sx={{ padding: '0 30px', height: '300px' }}>
                <StyledCarBox display='flex'>
                  <Typography
                    color='primary'
                    variant='h5'
                    sx={{ fontWeight: 'bold', marginRight: '7px', lineHeight: '1.2' }}
                  >
                    {car.make}
                  </Typography>
                  <Typography color='primary' variant='h6'>
                    {car.model} {car.volume}
                  </Typography>
                </StyledCarBox>
                <Typography
                  color='primary'
                  fontWeight='bold'
                  variant='body1'
                  sx={{ maxWidth: '100px', whiteSpace: 'nowrap' }}
                >
                  {car.variant}
                </Typography>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  sx={{
                    textOverflow: 'ellipsis',
                    maxHeight: '100px',
                    overflow: 'hidden',
                    whiteSpace: 'break-spaces',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 5,
                    display: '-webkit-box',
                    marginTop: '7px'
                  }}
                >
                  {/* {car.description} */}
                  <span dangerouslySetInnerHTML={{ __html: car.description.substring(0, 150) }} />
                </Typography>

                <StyledCarBox2>
                  <Typography variant='body2' color='textSecondary'>
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      Årsmodell:
                    </Box>{' '}
                    {car.yearModel}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      Km.stand:
                    </Box>{' '}
                    {car.mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  </Typography>
                  {postLocation && (
                    <Typography variant='body2' color='textSecondary'>
                      <Box component='span' sx={{ fontWeight: 'bold' }}>
                        Sted:
                      </Box>{' '}
                      {postLocation}
                    </Typography>
                  )}
                </StyledCarBox2>
              </CardContent>
              <div className='priceBox'>
                <Typography variant='h6'>Kr {car.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},-</Typography>
              </div>
            </CardActionArea>
          </Card>
        </Badge>
      </StyledCarCard>
    </Grid>
  );
};
