import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../api/fetcher';
import { LinearProgress, Card, CardMedia, Typography, Grid, Slide, Button, useTheme } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { ReactComponent as CalendarTodayIcon } from '../../images/calendar.svg';
import { ReactComponent as LocalGasStationIcon } from '../../images/gas.svg';
import { ReactComponent as AvTimerIcon } from '../../images/gauge.svg';
import { ReactComponent as TransmissionIcon } from '../../images/transmission.svg';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';

export const CarCarousel = ({ match, setTheme }) => {
  const handle = useFullScreenHandle();
  const { dealerId, locationId } = match.params;
  const [ele, setEle] = useState(0);

  const { data: cars } = useSWR(process.env.REACT_APP_BACKEND_URL + '/cars/' + dealerId, fetcher, {
    refreshInterval: 2 * 60 * 60 * 1000
  });
  const { data: dealer } = useSWR(process.env.REACT_APP_BACKEND_URL + '/dealer/' + dealerId, fetcher, {
    onSuccess: (data) => {
      if (data?.theme) {
        setTheme({
          palette: {
            primary: {
              main: dealer.theme.primary?.main ? dealer.theme.primary.main : null,
              light: dealer.theme.primary?.light ? dealer.theme.primary.light : null
            },
            secondary: {
              main: dealer.theme.secondary?.main ? dealer.theme.secondary.main : null,
              light: dealer.theme.secondary?.light ? dealer.theme.secondary.light : null
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'black';
  }, []);

  const carouselChange = (nextEle, prevEle) => {
    setEle(nextEle);
  };

  return (
    <>
      <Button
        style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1000 }}
        onClick={handle.enter}
        variant='outlined'
        color='secondary'
      >
        Enter fullscreen
      </Button>
      {cars ? (
        <FullScreen handle={handle}>
          {dealer?.name === 'Seljord bil AS' && (
            <img
              src='/images/seljordbil/logo.png'
              style={{ position: 'fixed', top: '30px', right: '30px', zIndex: 1000 }}
              alt=''
            />
          )}
          {dealer?.name === 'Auto 8-8' && (
            <img
              src='/images/auto88/logo.svg'
              style={{ position: 'fixed', top: '30px', right: '30px', width: '150px', zIndex: 1000 }}
              alt=''
            />
          )}

          <Carousel
            autoPlay={true}
            stopAutoPlayOnHover={false}
            interval={15000}
            animation={'slide'}
            duration={600}
            onChange={carouselChange}
            indicators={false}
            height={window.innerHeight}
          >
            {cars
              .filter((car) => (locationId ? car.location._id === locationId : true))
              .map(({ car }, i) => {
                return <Car key={i} car={car} ele={ele} index={i} />;
              })}
          </Carousel>
        </FullScreen>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

const Car = ({ car, ele, index }) => {
  return (
    <Card sx={{ height: '100vh', background: 'black', display: 'block' }}>
      <CardMedia style={{ backgroundColor: 'black' }} children={<CarImage {...car} ele={ele} index={index} />} />
    </Card>
  );
};

const CarImage = ({ images, make, model, price, variant, transmission, yearModel, mileage, fuel, ele, index }) => {
  const [checked, setCheked] = useState(false);
  const transitionDelays = ['400ms', '1000ms', '2000ms', '2250ms', '2500ms', '2750ms'];
  const [imageList, setImageList] = useState(images.sort((a, b) => (a.priority > b.priority ? 1 : -1)).slice(0, 5));
  const theme = useTheme();

  useEffect(() => {
    if (ele === index) {
      setTimeout(() => {
        setCheked(true);
      }, 1000);
    } else {
      setCheked(false);
    }
  }, [ele, index]);

  return (
    <>
      <div
        style={{
          zIndex: 1000,
          position: 'absolute',
          height: '100vh',
          top: '10px'
        }}
      >
        <Slide direction='up' style={{ transitionDelay: transitionDelays[2] }} in={checked}>
          <Grid sx={{ position: 'absolute', left: '100px', top: '25%' }}>
            <Grid container wrap='nowrap'>
              <Grid item style={{ padding: 0, marginRight: '10px' }}>
                <CalendarTodayIcon fill={theme.palette.primary.light} width='50' />
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <Typography color={'primary.light'}>Årsmodell</Typography>
                </Grid>
                <Grid item>
                  <Typography color={'primary.light'} sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }} variant='h6'>
                    {yearModel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Slide>

        <Slide direction='up' style={{ transitionDelay: transitionDelays[3] }} in={checked}>
          <Grid sx={{ position: 'absolute', left: '100px', top: '40%' }}>
            <Grid container wrap='nowrap'>
              <Grid item style={{ padding: 0, marginRight: '10px' }}>
                <AvTimerIcon fill={theme.palette.primary.light} width='50' />
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <Typography color={'primary.light'}>Kilometer</Typography>
                </Grid>
                <Grid item>
                  <Typography color={'primary.light'} sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }} variant='h6'>
                    {mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Slide>
        <Slide direction='up' style={{ transitionDelay: transitionDelays[4] }} in={checked}>
          <Grid sx={{ position: 'absolute', left: '100px', top: '55%' }}>
            <Grid container wrap='nowrap'>
              <Grid item style={{ padding: 0, marginRight: '10px' }}>
                <TransmissionIcon fill={theme.palette.primary.light} width='50' />
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <Typography color={'primary.light'}>Girkasse</Typography>
                </Grid>
                <Grid item>
                  <Typography color={'primary.light'} sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }} variant='h6'>
                    {transmission}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Slide>

        <Slide direction='up' style={{ transitionDelay: transitionDelays[5] }} in={checked}>
          <Grid sx={{ position: 'absolute', left: '100px', top: '70%' }}>
            <Grid container wrap='nowrap'>
              <Grid item style={{ padding: 0, marginRight: '10px' }}>
                <LocalGasStationIcon fill={theme.palette.primary.light} width='50' />
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <Typography color={'primary.light'}>Drivstoff</Typography>
                </Grid>
                <Grid item>
                  <Typography color={'primary.light'} sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }} variant='h6'>
                    {fuel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Slide>

        <Slide direction='left' style={{ transitionDelay: transitionDelays[0] }} in={checked} timeout={500}>
          <Typography
            sx={{
              position: 'absolute',
              fontSize: '75px',
              top: '10px',
              left: '50px',
              color: '#fff',
              whiteSpace: 'nowrap'
            }}
          >
            {make} {model}
          </Typography>
        </Slide>

        <Slide direction='up' style={{ transitionDelay: transitionDelays[0] }} in={checked} timeout={500}>
          <Typography
            sx={{
              position: 'absolute',
              fontSize: '20px',
              top: '100px',
              left: '50px',
              color: '#fff',
              whiteSpace: 'nowrap'
            }}
          >
            {variant}
          </Typography>
        </Slide>

        <Slide direction='up' style={{ transitionDelay: transitionDelays[1] }} in={checked} timeout={900}>
          <Typography
            sx={{
              height: '100px',
              position: 'absolute',
              fontWeight: 'bold',
              fontSize: '60px',
              lineHeight: '100px',
              bottom: '0px',
              left: '0px',
              color: '#fff',
              padding: '0 20px 0 40px',
              whiteSpace: 'nowrap',
              background: theme.palette.primary.dark
            }}
          >
            {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},-
          </Typography>
        </Slide>
      </div>
      <Carousel
        autoPlay={checked}
        stopAutoPlayOnHover={false}
        interval={2000}
        // animation={effect}
        duration={330}
        indicators={false}
        height={window.innerHeight}
      >
        {imageList
          .filter((image) => image.hidden === undefined || image.hidden === false)
          .map((image, imIndex) => {
            // setNumImages((numImages) => numImages + 1);
            return (
              <div key={imIndex} style={{ height: '100vh' }}>
                <div
                  style={{
                    display: 'block',
                    height: '100vh',
                    marginLeft: '150px',
                    width: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                      'linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 200px, rgba(0,0,0,0) 400px), url(' +
                      image.url +
                      ')'
                  }}
                  alt=''
                />

                <img
                  style={{ display: 'none' }}
                  src={image.url}
                  alt=''
                  onError={(e) => {
                    console.log('Removing failed image', index, make, image.url);
                    setImageList((imagelist) =>
                      imagelist.map((image, imNum) => {
                        const hidden = imIndex === imNum ? true : false;
                        return { ...image, hidden: hidden };
                      })
                    );
                    // setImageList((imageList) => imageList.splice(index, 1));
                  }}
                />
              </div>
            );
          })}
      </Carousel>
    </>
  );
};
