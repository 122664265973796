import React, { useEffect, useState } from 'react';
import useToken from './useToken';
import { SnackbarProvider } from 'notistack';

import {
  Typography,
  Divider,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Link,
  useTheme
  // Snackbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Overview } from './Overview';
import { ListSettings } from './listing/ListSettings';
import { Users } from './Users';
import { Sellers } from './Sellers';
import { Finance } from './Finance';
import { Dealers } from './Dealers';
import { Menu } from './Menu';
import { CarList } from './cars/List';
import { MCList } from './cars/MCList';
import { DealerSettings } from './DealerSettings';
import { Filters } from './Filters';
import { Login, ResetPassword } from './Login';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export const Admin = ({ setTheme }) => {
  const { page, firstParam, secondParam } = useParams();
  const history = useHistory();
  const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { token, setToken } = useToken();
  const [dealers, setDealers] = useState();
  const [superUser, setSuperUser] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [currentDealer, setCurrent] = useState();
  const [mutate, setMutate] = useState();
  const [connected, setConnected] = useState(true);
  const [adminProps, setAdminProps] = useState({
    dealer: null,
    token: null,
    firstName: null
  });

  useEffect(() => {
    setTheme({
      palette: {
        primary: {
          main: '#116873',
          light: '#84cdcb'
        },
        secondary: {
          main: '#e27d60',
          light: '#e8a87c'
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const menuClick = (pageId) => {
    setMutate(Math.random());
    setDrawerOpen(false);
    history.push(`/admin/${pageId}`);
  };

  const logOut = () => {
    setToken('');
    setMutate(Math.random());
    setDrawerOpen(false);
    history.push('/admin');
  };

  const changeDealer = (event) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/admin/dealer/current', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({ dealerId: event.target.value })
    }).then(async (res) => {
      setDrawerOpen(false);
      setCurrent(event.target.value);
      history.push('/admin/' + page);
      window.scrollTo(0, 0);
      setAdminProps((adminProps) => ({
        ...adminProps,
        dealer: dealers?.find((dealer) => dealer.id === event.target.value)
      }));
    });
  };

  //Have to use useSWR and mutate from other components when updating.. so that dealers always are up to date, etc...
  useEffect(() => {
    if (token) {
      fetch(process.env.REACT_APP_BACKEND_URL + '/admin', {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
          'x-access-token': token,
          Authorization: 'Bearer ' + token
        }
      })
        .then((res) => {
          if (res.status === 401) {
            setToken('');
            return;
          }
          res.json().then((res) => {
            setDealers(res.dealers);
            setSuperUser(res.superUser);
            setCurrent(res.currentDealer);
            setAdminProps({
              token: token,
              dealer: res.dealers.find((dealer) => (res.currentDealer ? dealer.id === res.currentDealer : true)),
              firstName: res.firstName
            });
          });
        })
        .catch((err) => {
          console.log(err);
          setConnected(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, mutate]);

  const SelectDealer = ({ dealers, currentDealer }) => {
    return (
      <>
        {!dealers ? (
          <LinearProgress sx={{ width: '100%', textAlign: 'center' }} />
        ) : dealers?.length && dealers.length === 1 ? (
          <Typography variant='h6' component='div' sx={{ width: '100%', textAlign: 'center' }}>
            {dealers[0].name}
          </Typography>
        ) : (
          <FormControl fullwidth='true'>
            <Select variant='standard' id='dealer-select' value={currentDealer || ''} onChange={changeDealer}>
              {dealers?.map((dealer) => {
                return (
                  <MenuItem key={dealer.id} value={dealer.id}>
                    {dealer.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        '& .MuiTextFieldRoot': {
          margin: theme.spacing(1),
          width: '25ch'
        },
        display: 'flex'
      }}
    >
      <SnackbarProvider>
        <CssBaseline />
        {connected && token && (
          <>
            <AppBar position='fixed' sx={{ backgroundColor: 'black' }}>
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => {
                    setDrawerOpen(!drawerOpen);
                  }}
                  edge='start'
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant='body1'
                  display='block'
                  component='span'
                  onClick={() => {
                    history.push('/admin');
                    setMutate(Math.random());
                    window.scrollTo(0, 0);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {dealers?.find((dealer) => dealer?.id === currentDealer)?.name || 'BraAuto'}
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              onClose={() => setDrawerOpen(!drawerOpen)}
              // sx={{ width: '240px', flexShrink: 0 }}
              variant='temporary'
              anchor='left'
              open={drawerOpen}
            >
              <Container fixed sx={{ width: '240px', height: '64px', display: 'flex', alignItems: 'center' }}>
                {token && <SelectDealer dealers={dealers} currentDealer={currentDealer} />}
              </Container>
              <Divider />
              {token && <Menu menuClick={menuClick} logOut={logOut} superUser={superUser} selected={page} />}
            </Drawer>
            <main style={{ flexGrow: 1, backgroundColor: theme.palette.background.default, padding: theme.spacing(3) }}>
              <div style={{ height: '64px' }} />
              {page === 'sellers' ? (
                <Sellers {...adminProps} />
              ) : page === 'users' ? (
                <Users {...adminProps} dealers={dealers} />
              ) : page === 'finance' ? (
                <Finance {...adminProps} />
              ) : page === 'filters' ? (
                <Filters {...adminProps} setMutate={setMutate} />
              ) : page === 'cars' ? (
                <CarList {...adminProps} />
              ) : page === 'mc' ? (
                <MCList {...adminProps} mcId={firstParam} />
              ) : page === 'help' ? (
                <HelpPage {...adminProps} />
              ) : page === 'dealer' ? (
                <DealerSettings {...adminProps} />
              ) : page === 'dealers' ? (
                <Dealers {...adminProps} />
              ) : page === 'listsettings' ? (
                <ListSettings {...adminProps} setMutate={setMutate} />
              ) : (
                <Overview {...adminProps} menuClick={menuClick} />
              )}
            </main>
          </>
        )}
        {!token ? (
          page === 'reset-password' || page === 'new-password' ? (
            <ResetPassword token={firstParam} id={secondParam} setToken={setToken} />
          ) : (
            <Login setToken={setToken} />
          )
        ) : null}
        {!connected && (
          <div style={{ margin: 'auto' }}>
            <Typography variant='h2'>Feil!</Typography>
            <Typography variant='subtitle1'>Ingen kontakt med server.</Typography>
            <Link
              onClick={() => {
                window.location.reload();
              }}
            >
              Prøv å last siden på nytt.
            </Link>
            <LinearProgress color='secondary' />
          </div>
        )}
      </SnackbarProvider>
    </div>
  );
};

const HelpPage = () => {
  return <>Suport</>;
};
