/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment, useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from '../api/fetcher';
import { useAutosave } from 'react-autosave';
import axios from 'axios';

import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Collapse,
  Tooltip,
  Switch,
  useTheme,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import DatePicker from '@mui/lab/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const drawerWidth = 240;

// const useStyles = makeStyles({
//   root: {
//     '& label': {
//       marginTop: -3
//     }
//   },
//   label: {
//     display: 'inline-flex'
//   },
//   help: {
//     marginTop: '20px',
//     marginLeft: '-35px',
//     zIndex: 100
//   }
// });

export const Finance = ({ token, dealer }) => {
  // const [finances, setFinances] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = useState(null);

  const {
    data: finances,
    mutate,
    isValidating
  } = useSWR(token ? [process.env.REACT_APP_BACKEND_URL + `/admin/finances`, token, dealer.id] : null, fetcher);

  useEffect(() => {
    !open && mutate();
  }, [open, mutate]);

  const newFinance = () => {
    const newFinance = {
      // id: new ObjectID(),
      new: true
    };

    updateFinance(newFinance);
    mutate();
  };

  const updateFinance = useCallback(
    (finance) => {
      if (finance) {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + '/admin/finance', finance, {
            headers: { Authorization: 'Bearer ' + token, 'x-access-token': token }
          })
          .then((res) => {
            if (!finance.id) setOpen(res.id);

            // mutate();
            // setOpen(finance.id);
          })
          .catch(console.error);

        // mutate([...finances, finance]);
      }

      // // filter the list, and return it with the updated item
      // const filteredTodos = todos.filter(todo => todo.id !== '1')
      // return [...filteredTodos, updatedTodo]
    },
    [token]
  );

  const Details = (props) => {
    const { values, open, setOpen } = props;

    const [finance, setFinance] = useState(values);
    // const [updated, setUpdated] = useState();

    // console.log('details', finance, open);

    // useEffect(() => {
    //   if (open && open === finance.id) {
    //     console.log('finance', finance, open);
    //     setUpdated(finance);
    //   }
    // }, [finance, open]);

    useAutosave({ data: open && open === finance.id ? finance : null, onSave: updateFinance });

    const handleFom = (date) => {
      setFinance((finance) => {
        return {
          ...finance,
          fom: date
        };
      });
    };
    const handleTom = (date) => {
      setFinance((finance) => {
        return {
          ...finance,
          tom: date
        };
      });
    };
    const setEnabled = (event) => {
      setFinance((finance) => {
        return {
          ...finance,
          enabled: event.target.checked
        };
      });
    };
    const setMake = (event) => {
      // setFinance((finance) => {
      //   return {
      //     ...finance,
      //     enabled: event.target.checked
      //   };
      // });
    };

    const onChange = (event) => {
      setFinance((finance) => {
        return {
          ...finance,
          [event.target.id]: event.target.value
        };
      });
    };

    if (isValidating) return <LinearProgress />;

    return (
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() =>
                open === finance.id
                  ? (() => {
                      updateFinance(finance);
                      // mutate();
                      setOpen(null);
                    })()
                  : setOpen(finance.id)
              }
            >
              {open === finance.id ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            {finance.name}
          </TableCell>
          <TableCell align='right'>{finance.interest1}</TableCell>
          <TableCell align='left'>{finance.interest2}</TableCell>
          <TableCell align='right'>{finance.fom ? new Date(finance.fom).toLocaleDateString() : ''}</TableCell>
          <TableCell align='right'>{finance.tom ? new Date(finance.tom).toLocaleDateString() : ''}</TableCell>
          <TableCell align='right'>
            <Switch
              checked={finance.enabled || false}
              onChange={setEnabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open === finance.id} timeout='auto' unmountOnExit>
              <form noValidate autoComplete='off'>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='h6' gutterBottom component='div'>
                    Detaljer
                  </Typography>

                  <Grid container justifyContent='space-between' spacing={3}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                    <Grid item xs={12}>
                      <TextField id='name' label='Lagre som' value={finance.name} onChange={onChange} />
                      {/* <DatePicker
                          inputFormat='dd/MM/yyyy'
                          renderInput={(props) => <TextField {...props} label='Gyldig fra og med' />}
                          value={finance.fom || ''}
                          onChange={handleFom}
                        /> */}
                      {/*<DatePicker
                          inputFormat='dd/MM/yyyy'
                          renderInput={(props) => <TextField {...props} label='Gyldig til og med' />}
                          value={finance.tom || ''}
                          onChange={handleTom}
                        /> */}
                    </Grid>
                    {/* </LocalizationProvider> */}
                    <Grid item xs={12}>
                      <TextField
                        id='tinglysing'
                        type='number'
                        label='Tinglysingsgebyr'
                        value={finance.tinglysing}
                        onChange={onChange}
                      />
                      <TextField
                        id='etablering'
                        type='number'
                        label='Etableringsgebyr'
                        value={finance.etablering}
                        onChange={onChange}
                      />
                      <TextField id='termingebyr' label='Termingebyr' value={finance.termingebyr} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='interest1'
                        type='number'
                        label='Lav rente'
                        value={finance.interest1}
                        onChange={onChange}
                      />
                      <TextField
                        id='interest2'
                        type='number'
                        label='Høy rente'
                        value={finance.interest2}
                        onChange={onChange}
                      />
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          // variant='outlined'
                          id='equityPercentage'
                          type='number'
                          InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                          label='Egenandelsprosent'
                          value={finance.equityPercentage}
                          onChange={onChange}
                        />
                        <Tooltip
                          // className={classes.help}
                          title='Minimum egenandelandel for å få lav rente. Sett til 0 for å gi samme rente uansett.'
                        >
                          <HelpIcon />
                        </Tooltip>{' '}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <FormControl variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>Merke</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='make'
                    // value={finance.make || null}
                    onChange={setMake}
                  >
                    <MenuItem value={'Alle'}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl> */}
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <>
      <IconButton onClick={newFinance} aria-label='Add' style={{ backgroundColor: theme.palette.primary.light }}>
        <AddIcon />
      </IconButton>

      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Finansiering</TableCell>
              <TableCell colSpan={2} align='center'>
                Rente
              </TableCell>
              <TableCell align='right'>Gyldig fra</TableCell>
              <TableCell align='right'>Gyldig til</TableCell>
              <TableCell align='right'>Aktiv</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finances &&
              finances.map((data) => (
                <Details
                  key={data.id}
                  values={data}
                  // finance={data}
                  // setFinance={setFinance}
                  mutate={mutate}
                  token={token}
                  open={open}
                  setOpen={setOpen}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
