/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useAutosave } from 'react-autosave';
import Cropper from 'react-easy-crop';
import { useTheme } from '@emotion/react';
import {
  Paper,
  Grid,
  Button,
  ButtonGroup,
  CircularProgress,
  Tab,
  Tabs,
  IconButton,
  Toolbar,
  Box,
  Container,
  Fab,
  TextField,
  Menu,
  MenuItem,
  Divider,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete
} from '@mui/material';
import {
  Close,
  AttachMoney,
  Assignment,
  Policy,
  Grade,
  Add,
  RotateRight,
  RotateLeft,
  AspectRatio,
  CloseSharp,
  Check
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

const mcFields = [
  {
    title: 'MC',
    fields: [
      {
        label: 'Registreringsnummer',
        name: 'regNum'
      },
      {
        label: 'Merke',
        name: 'make'
      },
      {
        label: 'Modell',
        name: 'model',
        optional: true
      }
    ]
  },
  {
    title: 'Motor og chassis',
    fields: [
      {
        label: 'Drivstoff',
        name: 'fuel',
        options: [
          { label: 'Bensin', value: 'Bensin' },
          {
            label: 'Elektrisk',
            value: 'Elektrisk'
          }
        ]
      }
    ]
  },
  {
    title: 'Utstyr',
    fields: [
      {
        label: 'Utstyr',
        name: 'equipments',
        checks: [
          { value: '12V uttak', label: '12V uttak' },
          { value: 'ABS', label: 'ABS' },
          { value: 'Alarm', label: 'Alarm' },
          { value: 'Aut. demperjustering', label: 'Aut. demperjustering' },
          { value: 'Bagasjebrett', label: 'Bagasjebrett' },
          { value: 'Cruise control', label: 'Cruise control' },
          { value: 'Effektanlegg', label: 'Effektanlegg' },
          { value: 'Ekstra kåper', label: 'Ekstra kåper' },
          { value: 'Gaffelveske', label: 'Gaffelveske' },
          { value: 'Hendelbeskyttere', label: 'Hendelbeskyttere' },
          { value: 'Høyttalere', label: 'Høyttalere' },
          { value: 'Intercom', label: 'Intercom' },
          { value: 'Kjørecomputer', label: 'Kjørecomputer' },
          { value: 'Koppholder', label: 'Koppholder' },
          { value: 'LED lys', label: 'LED lys' },
          { value: 'Midtstøtte', label: 'Midtstøtte' },
          { value: 'Navigasjonssystem', label: 'Navigasjonssystem' },
          { value: 'Overtrekk', label: 'Overtrekk' },
          { value: 'Radio/CD/MP3', label: 'Radio/CD/MP3' },
          { value: 'Revers', label: 'Revers' },
          { value: 'Sidevesker', label: 'Sidevesker' },
          { value: 'Slepekrok', label: 'Slepekrok' },
          { value: 'Solodeksel', label: 'Solodeksel' },
          { value: 'Tankpad', label: 'Tankpad' },
          { value: 'Tanktrekk', label: 'Tanktrekk' },
          { value: 'Tankveske', label: 'Tankveske' },
          { value: 'Toppveske', label: 'Toppveske' },
          { value: 'Traction control', label: 'Traction control' },
          { value: 'Varmeholker', label: 'Varmeholker' },
          { value: 'Varmesete', label: 'Varmesete' },
          { value: 'Velteklosser', label: 'Velteklosser' },
          { value: 'Vindskjerm', label: 'Vindskjerm' },
          { value: 'Xenon lys', label: 'Xenon lys' }
        ]
      }
    ]
  }
];

export const EditMC = (params) => {
  const { vehicle, setVehicle, updateVehicle } = params;
  const history = useHistory();
  const [tabnr, setTabnr] = useState(0);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        setVehicle(null);
      }
    };
  });

  useAutosave({ data: vehicle, onSave: updateVehicle, saveOnUnmount: false, interval: 1000 });

  return (
    <Card elevation={0} sx={{ padding: 1 }}>
      <Toolbar variant='dense'>
        <Tabs
          // variant='dense'
          value={tabnr}
          onChange={(e, v) => setTabnr(v)}
          // variant='fullWidth'
          textColor='secondary'
          indicatorColor='secondary'
        >
          {/* <Tab icon={<TwoWheeler />} label='Detaljer' />
            <Tab icon={<PhotoCamera />} label='Bilder' />
            <Tab icon={<Tune />} label='Innstillinger' /> */}
          <Tab label='Detaljer' />
          <Tab label='Bilder' />
          <Tab label='Innstillinger' />
        </Tabs>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color='inherit'
          onClick={() => {
            history.push(`/admin/mc`);
            setVehicle(null);
          }}
        >
          <Close />
        </IconButton>
      </Toolbar>
      <Box sx={{ height: '20px' }} />
      {tabnr === 0 && <MC {...params} />}
      {tabnr === 1 && <Photos {...params} />}
      {tabnr === 2 && <Settings {...params} />}
    </Card>
  );
};

const MC = ({ vehicle, setVehicle }) => {
  const onChange = async ({ target }) => {
    const { name, value } = target;
    setVehicle({ ...vehicle, mc: { ...vehicle.mc, [name]: value } });
  };

  const onCheckChange = async (event, value) => {
    setVehicle({
      ...vehicle,
      mc: {
        ...vehicle.mc,
        equipments: vehicle.mc.equipments
          ? vehicle.mc.equipments?.findIndex((equipment) => equipment.text === value) >= 0
            ? vehicle.mc.equipments.filter((equipment) => equipment.text !== value)
            : [...vehicle.mc.equipments, { text: value }]
          : [{ text: value }]
      }
    });
  };

  return (
    <Grid container spacing={2}>
      {vehicle &&
        mcFields.map((category) => {
          return (
            <Grid item xs={12} sm={12} md={6} key={category.title}>
              <Paper>
                <Card>
                  <CardContent>
                    <Typography>{category.title}</Typography>
                    {category.fields.map((field) => {
                      return field.options ? (
                        <FormControl key={field.name} required={!field.optional} fullWidth size='small' margin='dense'>
                          <InputLabel id={'labe-for-' + field.name}>{field.label}</InputLabel>
                          <Select
                            labelId={'labe-for-' + field.name}
                            name={field.name}
                            value={vehicle.mc[field.name]}
                            label={field.label}
                            onChange={onChange}
                          >
                            {field.options.map((option) => (
                              <MenuItem key={`${field.name}-${option.value}`} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : field.checks ? (
                        <FormGroup key={field.name}>
                          <Autocomplete
                            multiple
                            id='tags-outlined'
                            options={field.checks}
                            freeSolo
                            value={vehicle.mc?.equipments}
                            onChange={onCheckChange}
                            getOptionLabel={(option) => {
                              console.log('Option', option);
                              return option.label ? option.label : option.text;
                            }}
                            // defaultValue={[top100Films[13]]}
                            // filterSelectedOptions
                            // isOptionEqualToValue
                            renderInput={(params) => <TextField {...params} label='Velg utstyr' placeholder='Søk..' />}
                          />

                          {/* {field.checks.map((check, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  value={check.value}
                                  checked={vehicle.mc?.equipments?.findIndex(equipment => equipment.text === check.value) >= 0 }
                                  onChange={onCheckChange}
                                />
                              }
                              label={check.label}
                            />
                          ))} */}
                        </FormGroup>
                      ) : (
                        <TextField
                          size='small'
                          margin='dense'
                          fullWidth
                          required={!field.optional}
                          color='primary'
                          key={field.name}
                          id={field.name}
                          label={field.label.charAt(0).toUpperCase() + field.label.slice(1)}
                          value={vehicle.mc[field.name]}
                          onChange={onChange}
                        />
                      );
                    })}
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          );
        })}
    </Grid>
  );
};

const Photos = ({ vehicle, setVehicle, token }) => {
  const theme = useTheme();
  const [cloudinary, setCloudinary] = useState();
  const [uploading, setUploading] = useState(false);
  const inputFileRef = useRef();

  const uploadImages = (files) => {
    setUploading(true);
    const formData = new FormData();
    [...files].forEach((file) => formData.append('images', file, file.name));

    formData.append(
      'data',
      JSON.stringify({
        ...vehicle
      })
    );

    fetch(process.env.REACT_APP_BACKEND_URL + '/admin/images', {
      method: 'POST',
      headers: {
        'x-access-token': token,
        Authorization: 'Bearer ' + token
      },
      body: formData
    })
      .then((res) => res.json())
      .then((cloudinaries) => {
        setUploading(false);
        setVehicle((vehicle) => ({
          ...vehicle,
          mc: {
            ...vehicle.mc,
            images: [
              ...(vehicle.mc.images || []),
              ...cloudinaries.map((cloudinary) => ({ public_id: cloudinary.public_id, url: cloudinary.secure_url }))
            ]
          }
        }));
      })
      .catch((err) => {
        setUploading(false);
      });
  };

  return (
    <>
      {uploading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            display='flex'
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > *': {
                margin: theme.spacing(1)
              }
            }}
          >
            {vehicle?.mc?.images?.length &&
              vehicle.mc.images.map((cloudinary, index) => {
                return (
                  <Paper key={'img' + index}>
                    <img
                      height='100px'
                      alt={''}
                      src={cloudinary.url}
                      onError={(e) => {
                        console.log('err', index);
                        // setImages([...images.filter((i) => i.priority !== image.priority)]);
                      }}
                      onClick={() => setCloudinary(cloudinary)}
                    />
                  </Paper>
                );
              })}

            <input
              ref={inputFileRef}
              accept='image/*'
              multiple
              style={{ display: 'none' }}
              onChangeCapture={(e) => {
                uploadImages(e.target.files);
              }}
              type='file'
            />
            <Fab sx={{ top: '25px' }} color='secondary' aria-label='add' onClick={() => inputFileRef.current.click()}>
              <Add />
            </Fab>
          </Box>
          {cloudinary && (
            <Container sx={{ height: '50vh', minHeight: '500px', position: 'relative' }}>
              <ImageEdit
                cloudinary={cloudinary}
                setEditImage={setCloudinary}
                token={token}
                setUploading={setUploading}
                setVehicle={setVehicle}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};

const ImageEdit = ({ cloudinary, setEditImage, token, setUploading, setVehicle }) => {
  const theme = useTheme();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(0);
  const aspects = [4 / 3, 16 / 9, 1];
  const [anchorEl, setAnchorEl] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveImage = useCallback(async () => {
    setUploading(true);
    const transformation = { ...croppedAreaPixels, angle: rotation, crop: 'crop' };

    fetch(process.env.REACT_APP_BACKEND_URL + '/admin/image/transform', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({ transformation: transformation, cloudinary: cloudinary })
    })
      .then((res) => res.json())
      .then((cloudinary) => {
        setUploading(false);
        setVehicle((vehicle) => ({
          ...vehicle,
          mc: {
            ...vehicle.mc,
            images: [
              ...vehicle.mc.images.filter((image) => image.public_id !== cloudinary.public_id),
              { ...cloudinary }
            ]
          }
        }));
      })
      .catch((err) => {
        setUploading(false);
      });
  }, [croppedAreaPixels, rotation, token, setUploading, setVehicle, cloudinary]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Cropper
        image={cloudinary.url}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        aspect={aspects[aspect]}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />

      <Fab
        sx={{ margin: theme.spacing(1) }}
        color='secondary'
        onClick={() => setRotation((rotation) => (rotation < 270 ? rotation + 90 : 0))}
      >
        <RotateRight />
      </Fab>
      <Fab
        sx={{ margin: theme.spacing(1) }}
        color='secondary'
        onClick={() => setRotation((rotation) => (rotation > -270 ? rotation - 90 : 0))}
      >
        <RotateLeft />
      </Fab>

      <Fab variant='extended' sx={{ margin: theme.spacing(1) }} color='secondary' onClick={handleClick}>
        <AspectRatio sx={{ mr: 1 }} />
        {aspect === 0 ? '4:3' : aspect === 1 ? '16:9' : '1:1'}
      </Fab>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem disabled>Velg bildeformat</MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAspect(0);
            handleClose();
          }}
        >
          4:3
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAspect(1);
            handleClose();
          }}
        >
          16:9
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAspect(2);
            handleClose();
          }}
        >
          1:1
        </MenuItem>
      </Menu>

      <div style={{ right: 0, top: 0, position: 'absolute' }}>
        <Fab
          variant='extended'
          sx={{ margin: theme.spacing(1) }}
          onClick={() => {
            setEditImage(null);
          }}
        >
          <CloseSharp sx={{ mr: 1 }} />
          Avbryt
        </Fab>
        <Fab
          variant='extended'
          sx={{ margin: theme.spacing(1) }}
          color='primary'
          onClick={() => {
            saveImage();
            setEditImage(null);
          }}
        >
          <Check sx={{ mr: 1 }} />
          Lagre
        </Fab>
      </div>
    </>
  );
};

const Settings = () => {
  return (
    <ButtonGroup orientation='vertical' style={{ padding: '20px' }}>
      <Button variant='contained' color='primary' component='span' startIcon={<AttachMoney />}>
        Finansiering
      </Button>
      <Button variant='contained' color='primary' component='span' startIcon={<Policy />}>
        Forsikring
      </Button>
      <Button variant='contained' color='primary' component='span' startIcon={<Grade />}>
        Garanti
      </Button>
      <Button variant='contained' color='primary' component='span' startIcon={<Assignment />}>
        Kjøpekontrakt
      </Button>
    </ButtonGroup>
  );
};
